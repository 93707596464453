import { cleanObject } from '@/common/functions/cleanObject';
import Api from '../api';

const { axios: api } = Api;
const queryString = require('query-string');

const PREFIX = 'report';

export const getAllPosibleFiledsToFilter = async () => {
  const data = await api.get(`/${PREFIX}/get-all-posible-fileds-to-filter`);

  return { data: data.data };
};

export const getAllFieldsByQuery = async (payload) => {
  const data = await api.post(`/${PREFIX}/get-all-fileds-by-query`, payload);
  return { data: data.data };
};

export const loadFeatureStats = async (payload) => {
  const data = await api.get(`/${PREFIX}/feature-usage/?days=${payload.days}`);

  return { data: data.data };
};

export const getMailSearchReport = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/mail-search-report?${queryString.stringify(
      cleanObject(payload),
      {
        arrayFormat: 'bracket',
      },
    )}`,
  );
  return { data: data.data };
};

export const findRefundationReport = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/redundation-report?${queryString.stringify(
      cleanObject(payload),
      {
        arrayFormat: 'bracket',
      },
    )}`,
  );
  return { data: data.data };
};

export const findPaymentReport = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/payment-report?${queryString.stringify(cleanObject(payload), {
      arrayFormat: 'bracket',
    })}`,
  );
  return { data: data.data };
};

export const loadRoadStats = async (payload) => {
  const data = await api.get(`/${PREFIX}/road-stats?days=${payload.days}`);

  return { data: data.data };
};

export const loadDashboardData = async (payload) => {
  const data = await api.get(`/${PREFIX}/all-dashboard-data`);

  return { data: data.data };
};

export const getQualityIndicatorReport = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/quality-indicator?${queryString.stringify(
      cleanObject(payload),
      {
        arrayFormat: 'bracket',
      },
    )}`,
  );
  return { data: data.data };
};

export const getQualityChatTestIndicatorReport = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/quality-chat-test-indicator?${queryString.stringify(
      cleanObject(payload),
      {
        arrayFormat: 'bracket',
      },
    )}`,
  );
  return { data: data.data };
};

export const findAdvertReport = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/advert-report?${queryString.stringify(cleanObject(payload), {
      arrayFormat: 'bracket',
    })}`,
  );
  return { data: data.data };
};

export const findRenewReport = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/renew-report?${queryString.stringify(cleanObject(payload), {
      arrayFormat: 'bracket',
    })}`,
  );
  return { data: data.data };
};

export const getMetaReport = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/meta-report?${queryString.stringify(cleanObject(payload), {
      arrayFormat: 'bracket',
    })}`,
  );
  return { data: data.data };
};

export const getUTMReport = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/utm-report?${queryString.stringify(cleanObject(payload), {
      arrayFormat: 'bracket',
    })}`,
  );
  return { data: data.data };
};
