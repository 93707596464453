import { useMutation } from 'react-query';
import { refundStripePayment } from '@/api/query/stripe';

export const useRefundStripe = (
  id,
  refetchCustomer,
  addMessage,
  setShowDecisionConfirmation,
) => {
  const mutation = useMutation(refundStripePayment, {
    onSuccess: () => {
      refetchCustomer();
      addMessage('Refunded via Stripe', 'success');
      setShowDecisionConfirmation(() => false);
    },
    onError: () => {
      addMessage('Something went wrong with Stripe refund', 'error');
    },
  });

  return mutation;
};
