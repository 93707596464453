import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import {
  getTranslation,
  getTranslationCommonWorkflow,
} from '@/api/query/workflow';
import BotMessage from '../Message/BotMessage';
import TalkingMicrofon from './TalkingMicrofon';
import UserMessage from './UserMessage';
import BotMessageTalking from './BotMessageTalking';
import { DemoConversationContext } from '@/common/contexts/DemoConversation';

export const TALKING_ROLES = {
  USER: 'user',
  BOT: 'bot',
};

export const TextInformationWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 20px 0;
`;

const TalkiMessage = () => {
  const [conversationWithChatGPT, setConversationWithChatGPT] = useState([]);
  const { dailyConversation } = useContext(DemoConversationContext);

  useEffect(() => {
    if (!dailyConversation) {
      return;
    }

    const mapedConversation = dailyConversation
      .filter((f) => f.role !== 'system')
      .map((cm) => ({
        role: cm.role === 'assistant' ? TALKING_ROLES.BOT : TALKING_ROLES.USER,
        message: cm.content,
      }));

    setConversationWithChatGPT(() => mapedConversation);
  }, [dailyConversation]);

  const [current, setCurrent] = useState();
  const [showMessagesTranslations, setShowMessagesTranslations] = useState([]);
  const [translationMessages, setTrnaslationMessages] = useState([]);

  const translationMutation = useMutation({
    mutationFn: (value) => getTranslationCommonWorkflow(value),
    onSuccess: ({ data }) => {
      setShowMessagesTranslations((prev) => [...prev, data.name]);
      setTrnaslationMessages((prev) => [
        ...prev,
        { name: data.name, data: data.translation },
      ]);
    },
  });

  const handleGetTranlationForBot = useCallback(
    (name, message) => {
      const translation = translationMessages.find((tm) => tm.name === name);

      if (translation) {
        if (showMessagesTranslations.includes(name)) {
          setShowMessagesTranslations((prev) => [
            ...prev.filter((name) => name !== name),
          ]);
        } else {
          setShowMessagesTranslations((prev) => [...prev, name]);
        }

        return;
      }

      const payload = {};
      payload.message = message;
      payload.name = name;

      translationMutation.mutate(payload);
    },
    [translationMessages, showMessagesTranslations],
  );

  const previousMessages = useMemo(
    () =>
      conversationWithChatGPT.map((element, i) =>
        element.role === TALKING_ROLES.BOT ? (
          <BotMessageTalking
            key={i}
            id={i}
            message={element.message}
            translationMessages={translationMessages}
            showMessagesTranslations={showMessagesTranslations}
            handleGetTranlationForBot={handleGetTranlationForBot}
          />
        ) : (
          <UserMessage message={element.message} key={i} />
        ),
      ),
    [
      conversationWithChatGPT,
      translationMessages,
      handleGetTranlationForBot,
      showMessagesTranslations,
    ],
  );

  return (
    <>
      {previousMessages}
      {current && <BotMessage text={current} showTranslateIcon={false} />}
      <TalkingMicrofon
        setCurrent={setCurrent}
        setConversationWithChatGPT={setConversationWithChatGPT}
        conversationWithChatGPT={conversationWithChatGPT}
      />
    </>
  );
};

export default TalkiMessage;
