import React, { useRef, useState } from 'react';
import { styled } from 'styled-components';
import { Color } from '@/common/colors/colors';
import moment from 'moment';
import Input from '@/common/components/custom/Input';
import SelectInput from '@/common/components/custom/SelectInput';
import CSV from '@/common/components/CSV';

const SelectBarContainer = styled.form`
  border-radius: 10px;
  background-color: ${Color.White};
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border: 1px dashed grey;
`;

const SearchButton = styled.button`
  padding: 10px 20px;
  background-color: ${Color.DarkBlue};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-left: 20px;

  &:hover {
    background-color: ${Color.Grey};
  }
`;

const SearchBar = ({ handleSearch, csvData, headers }) => {
  const fromRef = useRef();
  const toRef = useRef();

  const handleSearchCheck = (e) => {
    e?.preventDefault();

    const payload = {};

    payload.from = fromRef.current.value;
    payload.to = toRef.current.value;

    handleSearch(payload);
  };

  return (
    <>
      <SelectBarContainer onSubmit={handleSearchCheck}>
        <Input
          type="date"
          inputRef={fromRef}
          name="Payment / Refund Date From"
          inputWidth={120}
          width={280}
          required={true}
          value={moment().format('YYYY-MM-DD')}
        />
        <Input
          type="date"
          inputRef={toRef}
          name="Payment / Refund Date To"
          inputWidth={120}
          width={250}
          required={true}
          value={moment().format('YYYY-MM-DD')}
        />
        <SearchButton type="submit">Search</SearchButton>
        {csvData.length > 0 && (
          <div style={{ marginLeft: '20px' }}>
            <CSV header={headers} data={csvData} filename={'payment_report'} />
          </div>
        )}
      </SelectBarContainer>
    </>
  );
};

export default SearchBar;
