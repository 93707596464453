import CustomTable from '@/common/components/custom/CustomTable';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import SearchBar from './components/SearchBar';
import Loading from '@/common/components/Loading';
import { findAdvertReport } from '@/api/query/report';
import { formatDateShort } from '../mail/AdminMail';
import {
  HEADERS,
  REFUND_HEADERS,
  RENEW_HEADERS,
} from './constants/tableHeaders';
import { getCountries } from '@/api/query/country';

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const FinancialReport = () => {
  const [tableData, setTableData] = useState([]);
  const [tableDataReduns, setTableDataRefund] = useState([]);
  const [tableDataProfit, setTableDataProfit] = useState([]);
  const [tableDataRenew, setTableDataRenew] = useState([]);
  const [tableDataRealProfit, setTableDataRealProfit] = useState([]);

  const [countriesOptions, setCountriesOption] = useState([]);

  const round = (num) => num?.toFixed(2);

  const calculateByFiled = (arr, field) =>
    arr.reduce((prev, next) => prev + next[field], 0);

  const calculateByFileds = (arr, fields) =>
    arr.reduce(
      (prev, next) =>
        prev +
        fields.reduce((curPrev, curNext) => curPrev || 0 + next[curNext] || 0),
      0,
    );

  const findStatsMutation = useMutation({
    mutationFn: (values) => findAdvertReport(values),
    onSuccess: ({ data }) => {
      setTableData(() => [
        ...data.allIncluded.map((d, i) => [
          i + 1,
          formatDateShort(d.value.from),
          formatDateShort(d.value.to),
          d.key,
          round(d.value.spend),
          round(d.value.spendVat),
          d.value.purchase,
          round(d.value.purchaseValue),
          round(d.value.spendVat / d.value.purchase),
          round(d.value.purchaseValue / d.value.spendVat),
          round(d.value.purchaseValue - d.value.spendVat),
        ]),
        [
          '----',
          '----',
          '----',
          '----',
          round(
            calculateByFiled(
              data.allIncluded.map((d) => d.value),
              'spend',
            ),
          ),
          round(
            calculateByFiled(
              data.allIncluded.map((d) => d.value),
              'spendVat',
            ),
          ),
          calculateByFiled(
            data.allIncluded.map((d) => d.value),
            'purchase',
          ),
          round(
            calculateByFiled(
              data.allIncluded.map((d) => d.value),
              'purchaseValue',
            ),
          ),
          round(
            calculateByFiled(
              data.allIncluded.map((d) => d.value),
              'spendVat',
            ) /
              calculateByFiled(
                data.allIncluded.map((d) => d.value),
                'purchase',
              ),
          ),
          round(
            calculateByFiled(
              data.allIncluded.map((d) => d.value),
              'purchaseValue',
            ) /
              calculateByFiled(
                data.allIncluded.map((d) => d.value),
                'spendVat',
              ),
          ),
          round(
            calculateByFiled(
              data.allIncluded.map((d) => d.value),
              'purchaseValue',
            ) -
              calculateByFiled(
                data.allIncluded.map((d) => d.value),
                'spendVat',
              ),
          ),
        ],
      ]);

      setTableDataRefund(() => [
        ...data.allIncluded.map((d, i) => [
          i + 1,
          formatDateShort(d.value.from),
          formatDateShort(d.value.to),
          d.key,
          d.value.refund,
          round(d.value.refundValue),
          round(d.value.refundValue * -1),
        ]),
        [
          '----',
          '----',
          '----',
          '----',
          calculateByFiled(
            data.allIncluded.map((d) => d.value),
            'refund',
          ),
          round(
            calculateByFiled(
              data.allIncluded.map((d) => d.value),
              'refundValue',
            ),
          ),
          round(
            calculateByFiled(
              data.allIncluded.map((d) => d.value),
              'refundValue',
            ) * -1,
          ),
        ],
      ]);

      setTableDataRenew(() => [
        ...data.allIncluded.map((d, i) => [
          i + 1,
          formatDateShort(d.value.from),
          formatDateShort(d.value.to),
          d.key,
          d.value.renew,
          round(d.value.renewValue),
        ]),
        [
          '----',
          '----',
          '----',
          '----',
          calculateByFiled(
            data.allIncluded.map((d) => d.value),
            'renew',
          ),
          round(
            calculateByFiled(
              data.allIncluded.map((d) => d.value),
              'renewValue',
            ),
          ),
        ],
      ]);

      setTableDataProfit(() => {
        const allPruchase = data.allIncluded.reduce(
          (prev, next) => prev + next.value.purchase - next.value.refund,
          0,
        );
        const allPruchaseValue = round(
          data.allIncluded.reduce(
            (prev, next) =>
              prev + next.value.purchaseValue - next.value.refundValue,
            0,
          ),
        );

        return [
          ...data.allIncluded.map((d, i) => {
            const pruchase = d.value.purchase - d.value.refund;
            const pruchaseValue = round(
              d.value.purchaseValue - d.value.refundValue,
            );

            return [
              i + 1,
              formatDateShort(d.value.from),
              formatDateShort(d.value.to),
              d.key,
              round(d.value.spend),
              round(d.value.spendVat),
              pruchase,
              pruchaseValue,
              round(d.value.spendVat / pruchase),
              round(pruchaseValue / d.value.spendVat),
              round(pruchaseValue - d.value.spendVat),
            ];
          }),
          [
            '----',
            '----',
            '----',
            '----',
            round(
              calculateByFiled(
                data.allIncluded.map((d) => d.value),
                'spend',
              ),
            ),
            round(
              calculateByFiled(
                data.allIncluded.map((d) => d.value),
                'spendVat',
              ),
            ),
            allPruchase,
            allPruchaseValue,
            round(
              calculateByFiled(
                data.allIncluded.map((d) => d.value),
                'spendVat',
              ) / allPruchase,
            ),
            round(
              allPruchaseValue /
                calculateByFiled(
                  data.allIncluded.map((d) => d.value),
                  'spendVat',
                ),
            ),
            round(
              allPruchaseValue -
                calculateByFiled(
                  data.allIncluded.map((d) => d.value),
                  'spendVat',
                ),
            ),
          ],
        ];
      });

      setTableDataRealProfit(() => {
        const allPruchase = data.allIncluded.reduce(
          (prev, next) =>
            prev + next.value.purchase + next.value.renew - next.value.refund,
          0,
        );
        const allPruchaseValue = round(
          data.allIncluded.reduce(
            (prev, next) =>
              prev +
              next.value.purchaseValue +
              next.value.renewValue -
              next.value.refundValue,
            0,
          ),
        );

        return [
          ...data.allIncluded.map((d, i) => {
            const pruchase = d.value.purchase + d.value.renew - d.value.refund;
            const pruchaseValue = round(
              d.value.purchaseValue + d.value.renewValue - d.value.refundValue,
            );

            return [
              i + 1,
              formatDateShort(d.value.from),
              formatDateShort(d.value.to),
              d.key,
              round(d.value.spend),
              round(d.value.spendVat),
              pruchase,
              pruchaseValue,
              round(d.value.spendVat / pruchase),
              round(pruchaseValue / d.value.spendVat),
              round(pruchaseValue - d.value.spendVat),
            ];
          }),
          [
            '----',
            '----',
            '----',
            '----',
            round(
              calculateByFiled(
                data.allIncluded.map((d) => d.value),
                'spend',
              ),
            ),
            round(
              calculateByFiled(
                data.allIncluded.map((d) => d.value),
                'spendVat',
              ),
            ),
            allPruchase,
            allPruchaseValue,
            round(
              calculateByFiled(
                data.allIncluded.map((d) => d.value),
                'spendVat',
              ) / allPruchase,
            ),
            round(
              allPruchaseValue /
                calculateByFiled(
                  data.allIncluded.map((d) => d.value),
                  'spendVat',
                ),
            ),
            round(
              allPruchaseValue -
                calculateByFiled(
                  data.allIncluded.map((d) => d.value),
                  'spendVat',
                ),
            ),
          ],
        ];
      });
    },
  });

  const findNativeCountriesMutation = useMutation({
    mutationFn: () => getCountries(),
    onSuccess: ({ data }) => {
      const countries = data.map((d) => ({ label: d.name, value: d._id }));
      setCountriesOption(() => countries);
    },
  });

  const handleSearch = (payload) => {
    findStatsMutation.mutate(payload);
  };

  useEffect(() => {
    findNativeCountriesMutation.mutate();
  }, []);

  return (
    <>
      {findStatsMutation.isLoading && <Loading />}
      <Wrapper>
        <TableWrapper>
          <SearchBar
            handleSearch={handleSearch}
            countriesOptions={countriesOptions}
          />
        </TableWrapper>
        <h3>Advert Report (Karolina) ( completed, refunded)</h3>
        <TableWrapper>
          <CustomTable headers={HEADERS} data={tableData} />
        </TableWrapper>
        <h3>Real Profit Report( completed, renew, - refunded)</h3>
        <TableWrapper>
          <CustomTable headers={HEADERS} data={tableDataRealProfit} />
        </TableWrapper>
        <h3>Profit Report ( completed, - refunded)</h3>
        <TableWrapper>
          <CustomTable headers={HEADERS} data={tableDataProfit} />
        </TableWrapper>
        <h3>Renew Report</h3>
        <TableWrapper>
          <CustomTable headers={RENEW_HEADERS} data={tableDataRenew} />
        </TableWrapper>
        <h3>Refundation Report</h3>
        <TableWrapper>
          <CustomTable headers={REFUND_HEADERS} data={tableDataReduns} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default FinancialReport;
