import React from 'react';
import PopUp from '../../PopUp';
import styled from 'styled-components';
import NewCustomTable from '../../custom/NewCustomTable';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const PopUpWrapper = styled.div`
  padding: 10px 10px;
  height: fit-content;
  max-height: 80vh;
  overflow: auto;
  color: #05275d;
`;

const TableWrapper = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const SearchPopUp = ({ setData, data }) => {
  return (
    <PopUp setClose={setData}>
      <PopUpWrapper>
        <h2>Customer Search Result</h2>
        <h3>Exact email</h3>
        <TableWrapper>
          <NewCustomTable
            headers={['No.', 'Name', 'Email', 'Actions']}
            data={data.exactUser.map((d, i) => [
              i + 1,
              d.name || '-----',
              d.email || '-----',
              <div>
                <Link
                  to={`/admin/customer/stats/${d._id}`}
                  onClick={() => setData(null)}
                >
                  <FontAwesomeIcon icon={faEye} />
                </Link>
              </div>,
            ])}
          />
        </TableWrapper>
        <h3>SubRegex Search</h3>
        <TableWrapper>
          <NewCustomTable
            headers={['No.', 'Name', 'Email', 'Actions']}
            data={data.suggestedUsers.map((d, i) => [
              i + 1,
              d.name || '-----',
              d.email || '-----',
              <div>
                <Link
                  to={`/admin/customer/stats/${d._id}`}
                  onClick={() => setData(null)}
                >
                  <FontAwesomeIcon icon={faEye} />
                </Link>
              </div>,
            ])}
          />
        </TableWrapper>
        <h3>Regex Search</h3>
        <TableWrapper>
          <NewCustomTable
            headers={['No.', 'Name', 'Email', 'Actions']}
            data={data.oldSearch.map((d, i) => [
              i + 1,
              d.name || '-----',
              d.email || '-----',
              <div>
                <Link
                  to={`/admin/customer/stats/${d._id}`}
                  onClick={() => setData(null)}
                >
                  <FontAwesomeIcon icon={faEye} />
                </Link>
              </div>,
            ])}
          />
        </TableWrapper>
      </PopUpWrapper>
    </PopUp>
  );
};

export default SearchPopUp;
