export const HEADERS = [
  'No.',
  'From',
  'To',
  'Advert Source',
  'Spend Netto',
  'Spend Brutto',
  'Payment',
  'Payment Value',
  'Payment Cost Brutto',
  'ROAS Brutto - Brutto',
  'Profit',
];

export const REFUND_HEADERS = [
  'No.',
  'From',
  'To',
  'Advert Source',
  'Refunded',
  'Refunded Value',
  'Profit',
];

export const RENEW_HEADERS = [
  'No.',
  'From',
  'To',
  'Advert Source',
  'Renew',
  'Renew Value',
];
