import React from 'react';
import PopUp from '@/common/components/PopUp';
import CustomDeleteButton from '@/common/components/custom/buttons/CustomDeleteButton';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import styled from 'styled-components';

const CancelSubWrapper = styled.div`
  margin: 20px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const CancelSubscriptionPopUp = ({ handleDecision, setShow }) => (
  <PopUp
    title="Confirm Subscription Cancellation"
    onClose={() => setShow(false)}
  >
    <CancelSubWrapper>
      <p>Are you sure you want to cancel the subscription?</p>
      <ButtonWrapper>
        <CustomDeleteButton onClick={handleDecision} text={'Yes'} />
        <CustomEditButton onClick={() => setShow(false)} text={'No'} />
      </ButtonWrapper>
    </CancelSubWrapper>
  </PopUp>
);

export default CancelSubscriptionPopUp;
