import Loading from '@/common/components/Loading';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import ArrLeft from '@/common/images/arr-left.svg';
import { findLessonsForCustomer } from '@/api/query/lesson';
import FilledHeart from './images/filled-heart.svg';
import BlankHeart from './images/blank-heart.svg';
import { AddToFavorite, findFavoriteByType } from '@/api/query/customer';
import PopUp from '@/common/components/PopUp';
import WriteOpinion, {
  OPINION_OPTIONS,
} from '../profile/components/WriteOpinion';
import { BoxOpinion } from '../word-lesson-type/WordLessonTypeList';
import ArrRightViolet from '../word-lesson-type/images/arr-right-violet.svg';
import { MessageContext } from '@/common/contexts/MessageContext';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { CustomerDataContext } from '@/common/components/layout/productLayout/context/CustomerDataContext';

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 60px);
  overflow-y: auto;
  text-align: center;
  padding: 10px 10px 0 10px;
  gap: 10px;

  @media (min-width: 600px) {
    flex-grow: 1;
    height: calc(var(--vh, 1vh) * 100 - 160px);
    width: 100%;
    padding: 30px 30px 10px;
    margin: 0;
  }
`;

export const Title = styled.h2`
  position: sticky;
  top: 0;
  color: #05275d;
  width: 100%;
  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
  margin: 0px;
  text-align: center;
  z-index: 1;
`;

const CategoriesWrapper = styled.div`
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;

  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  max-width: 150px;

  @media (min-width: 350px) {
    max-width: 310px;
  }

  @media (min-width: 600px) {
    max-width: 430px;
    gap: 24px;
  }

  @media (min-width: 772px) {
    max-width: 650px;
  }

  @media (min-width: 1000px) {
    max-width: 900px;
  }
`;

const Category = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 150px;
  height: 150px;
  padding: 10px 5px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
  color: #29185e;
  gap: 4px;

  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }

  @media (min-width: 600px) {
    width: 200px;
    height: 200px;

    &:hover {
      transform: scale(0.8);
      cursor: pointer;
    }
  }
`;

const LessonTitle = styled.h2`
  color: #29185e;
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  @media (min-width: 600px) {
    font-size: 18px;
  }
`;

const TopTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%; /* 24.7px */
  letter-spacing: 0.4px;
  position: absolute;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  div {
    max-width: 130px;
  }
`;

const SubTitle = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 17.6px */
  max-width: 450px;
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: auto;
  gap: 16px;
  padding-bottom: 20px;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 24, 94, 0.5); /* Kolor nakładki */
  border-radius: 8px; /* Zaokrąglenie krawędzi */
`;

const Heart = styled.div`
  position: absolute;
  right: 5px;
  bottom: 0px;
  width: 25px;
  z-index: 2;

  img {
    width: 100%;
    height: auto;
  }
`;

const Box = styled(Link)`
  position: relative; /* Ustawiamy pozycję na relative */
  width: 160px;
  height: 160px;
  border-radius: 8px; /* Zaokrąglenie krawędzi */
  background-color: white !important;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
`;

const ImageWrapper = styled.div`
  width: 160px;
  height: 160px;
  position: relative; /* Ustawiamy pozycję na relative */
  overflow: hidden;

  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }
`;

const StartPopUpTitle = styled.h4`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
`;

const StartPopUpSubtitle = styled.h4`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
`;

const PopUpStartWrapper = styled.div`
  max-width: 350px;
  text-align: center;
`;

const ConfirmationButton = styled.button`
  display: flex;
  width: 180px;
  padding: 14px 63px 14px 62px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(
    --Purple,
    linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%)
  );

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  margin: 40px auto 10px auto;
`;

const Lessons = () => {
  const { title, subtitle, suggest, talk } = useLanguageData('features.scene');
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState();
  const [showOption, setShowOpinion] = useState();

  const findCtegoriesMutation = useMutation({
    mutationFn: () => findLessonsForCustomer(),
    onSuccess: ({ data }) => {
      setCategories(data);
    },
  });

  const { manageFavoriteMutation, favoriteArr } =
    useContext(CustomerDataContext);

  const handleAddToFavorite = (id, type) => {
    const payload = {
      _id: id,
      type,
      shouldAdd: favoriteArr?.includes(id) ? false : true,
    };

    manageFavoriteMutation.mutate(payload);
  };

  useLayoutEffect(() => {
    findCtegoriesMutation.mutate();
    window.document.title = `Synthi Ai - ${title}`;
  }, []);

  return (
    <>
      {(manageFavoriteMutation.isLoading ||
        findCtegoriesMutation.isLoading) && <Loading />}
      <Wrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Link to={'/learn'}>
            <img
              src={ArrLeft}
              style={{
                width: '12px',
              }}
            />
          </Link>
          <Title>{title}</Title>
          <div></div>
        </div>
        <SubTitle>{subtitle}</SubTitle>
        <BoxWrapper>
          {categories.map((d) => (
            <Box
              onClick={() => {
                setShowConfirmation(d);
              }}
            >
              <ImageWrapper>
                <TopTitle>
                  <div>{d.name}</div>
                </TopTitle>
                <img src={d.image.base64} alt={d.name} />
                <ImageOverlay />
                <Heart
                  onClick={(e) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    handleAddToFavorite(d._id, 'scenes');
                  }}
                >
                  <img
                    src={favoriteArr.includes(d._id) ? FilledHeart : BlankHeart}
                  />
                </Heart>
              </ImageWrapper>
            </Box>
          ))}
          <BoxOpinion onClick={() => setShowOpinion(true)}>
            <div>{suggest}</div>
            <div>
              <img src={ArrRightViolet} />
            </div>
          </BoxOpinion>
        </BoxWrapper>
      </Wrapper>
      {showConfirmation && (
        <PopUp closeIcon={false} setClose={setShowConfirmation}>
          <PopUpStartWrapper>
            <StartPopUpTitle>{showConfirmation.name}</StartPopUpTitle>
            <StartPopUpSubtitle>
              {showConfirmation.description}
            </StartPopUpSubtitle>
            <ConfirmationButton
              onClick={() => navigate(`/learn/scenes/${showConfirmation._id}`)}
            >
              {talk}
            </ConfirmationButton>
          </PopUpStartWrapper>
        </PopUp>
      )}
      {showOption && (
        <WriteOpinion setShow={setShowOpinion} from={OPINION_OPTIONS.SCENE} />
      )}
    </>
  );
};

export default Lessons;
