import CustomTable from '@/common/components/custom/CustomTable';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import SearchBar from './components/SearchBar';
import Loading from '@/common/components/Loading';
import { findRenewReport } from '@/api/query/report';
import { getFormatedDate } from '@/common/functions/getFormatedDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { getCountries } from '@/api/query/country';

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const HEADERS = [
  'No.',
  'Email',
  'Customer Created Date',
  'First Payment',
  'Last Payment',
  'Expired Date',
  'Payments',
  'Spent',
  'Show',
];

const RenewReport = () => {
  const [tableData, setTableData] = useState([]);
  const [countriesOptions, setCountriesOption] = useState([]);

  const round = (num) => num?.toFixed(2);

  const findStatsMutation = useMutation({
    mutationFn: (values) => findRenewReport(values),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d) => [
          d.no,
          d.email,
          getFormatedDate(d.created_date),
          getFormatedDate(d.first_payment),
          getFormatedDate(d.last_payment),
          getFormatedDate(d.expired_date),
          d.payments,
          `${round(d.spent)} ${d.currency}`,
          <div>
            <Link to={`/admin/customer/stats/${d._id}`}>
              <FontAwesomeIcon icon={faEye} />
            </Link>
          </div>,
        ]),
      );
    },
  });

  const findCountriesMutation = useMutation({
    mutationFn: () => getCountries(),
    onSuccess: ({ data }) => {
      const countries = data.map((d) => ({ label: d.name, value: d._id }));
      setCountriesOption(() => countries);
    },
  });

  const handleSearch = (payload) => {
    findStatsMutation.mutate(payload);
  };

  useEffect(() => {
    findCountriesMutation.mutate();
  }, []);

  return (
    <>
      {findStatsMutation.isLoading && <Loading />}
      <Wrapper>
        <TableWrapper>
          <SearchBar
            handleSearch={handleSearch}
            countriesOptions={countriesOptions}
          />
        </TableWrapper>
        <TableWrapper>
          <CustomTable headers={HEADERS} data={tableData} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default RenewReport;
