import React, { useRef, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import SearchBar from './components/SearchBar';
import Loading from '@/common/components/Loading';
import { getMetaReport } from '@/api/query/report';
import NewCustomTable from '@/common/components/custom/NewCustomTable';
import Input from '@/common/components/custom/Input';
import { getFormatedDate } from '@/common/functions/getFormatedDate';

const Section = styled.div``;

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  max-width: 90vw;
  overflow-x: auto;
`;

const HEADERS = (shoudDisplayName) =>
  shoudDisplayName
    ? [
        'No.',
        'Spend Netto',
        'Spend Brutto',
        'Payment',
        'Payment Value',
        'Payment Cost Brutto',
        'ROAS Brutto Brutto',
        'Profit',
      ]
    : [
        'No.',
        'Adset Name',
        'Spend Netto',
        'Spend Brutto',
        'Payment',
        'Payment Value',
        'Payment Cost Brutto',
        'ROAS Brutto Brutto',
        'Profit',
      ];

const AdminMetaReport = () => {
  const [tableData, setTableData] = useState([]);
  const [filterValue, setFilterValue] = useState();
  const uniqueUid = useRef();

  const round = (num) => num.toFixed(2);

  const findStatsMutation = useMutation({
    mutationFn: (values) => getMetaReport(values),
    onSuccess: ({ data }) => {
      if (uniqueUid.current.checked) {
        setTableData(() =>
          data
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((d, i) => [
              `${i + 1}.`,
              round(d.spending),
              round(d.spending * 1.23),
              d.bought,
              round(d.value),
              round((d.spending * 1.23) / d.bought),
              round(d.value / (d.spending * 1.23)),
              round(d.value - d.spending * 1.23),
            ]),
        );

        return;
      }
      setTableData(() =>
        data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((d, i) => [
            `${i + 1}.`,
            d.name,
            round(d.spending),
            round(d.spending * 1.23),
            d.bought,
            round(d.value),
            round((d.spending * 1.23) / d.bought),
            round(d.value / (d.spending * 1.23)),
            round(d.value - d.spending * 1.23),
          ]),
      );
    },
  });

  const handleSearch = (payload) => {
    findStatsMutation.mutate(payload);
  };

  return (
    <>
      {findStatsMutation.isLoading && <Loading />}
      <Wrapper>
        <TableWrapper>
          <SearchBar handleSearch={handleSearch} toogleRef={uniqueUid} />
        </TableWrapper>
        <Section>
          <Input
            name="Includer"
            inputWidth={220}
            width={80}
            onChange={(e) => setFilterValue(e.target.value)}
          />
        </Section>
        {tableData && (
          <TableWrapper>
            <NewCustomTable
              headers={HEADERS(uniqueUid?.current?.checked)}
              data={tableData.filter((td) => {
                if (uniqueUid?.current?.checked) {
                  return true;
                }

                if (!filterValue || filterValue?.length < 3) {
                  return true;
                }

                if (
                  td[1]?.toLowerCase()?.includes(filterValue?.toLowerCase())
                ) {
                  return true;
                }

                return false;
              })}
              csvData={tableData}
            />
          </TableWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default AdminMetaReport;
