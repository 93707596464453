import { useContext, useMemo } from 'react';
import EN_LANG from '../../assets/en.js';
import PL_LANG from '../../assets/pl.js';
import ES_LANG from '../../assets/es.js';
import DE_LANG from '../../assets/de.js';
import HR_LANG from '../../assets/hr.js';
import IT_LANG from '../../assets/it.js';
import RO_LANG from '../../assets/ro.js';
import SK_LANG from '../../assets/sk.js';
import CS_LANG from '../../assets/cs.js';
import HU_LANG from '../../assets/hu.js';
import BG_LANG from '../../assets/bg.js';
import SV_LANG from '../../assets/sv.js';
import ET_LANG from '../../assets/et.js';

import { LANG_OPTIONS, LanguageContext } from '../contexts/LanguageContext.js';

export const useLanguageData = (page) => {
  const { lang } = useContext(LanguageContext);
  const pageData = useMemo(() => {
    if (!lang) {
      return {};
    }

    let data = {};

    if (lang === LANG_OPTIONS.english) {
      data = EN_LANG;
    }
    if (lang === LANG_OPTIONS.croatian) {
      data = HR_LANG;
    }

    if (lang === LANG_OPTIONS.italian) {
      data = IT_LANG;
    }
    if (lang === LANG_OPTIONS.romanian) {
      data = RO_LANG;
    }
    if (lang === LANG_OPTIONS.slovak) {
      data = SK_LANG;
    }
    if (lang === LANG_OPTIONS.czech) {
      data = CS_LANG;
    }
    if (lang === LANG_OPTIONS.polish) {
      data = PL_LANG;
    }
    if (lang === LANG_OPTIONS.spanish) {
      data = ES_LANG;
    }
    if (lang === LANG_OPTIONS.german) {
      data = DE_LANG;
    }

    if (lang === LANG_OPTIONS.hungarian) {
      data = HU_LANG;
    }

    if (lang === LANG_OPTIONS.bulgarian) {
      data = BG_LANG;
    }

    if (lang === LANG_OPTIONS.swedish) {
      data = SV_LANG;
    }

    if (lang === LANG_OPTIONS.estonian) {
      data = ET_LANG;
    }

    let path = page.split('.');
    let result = data[path[0]];

    for (let i = 1; i < path.length; i++) {
      result = result[path[i]];
    }

    return result;
  }, [lang]);

  return pageData;
};
