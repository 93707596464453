import { useMutation } from 'react-query';
import { cancelStripeSub } from '@/api/query/stripe';

export const useCancelStripeSub = (
  addMessage,
  setShowCancelSubscription,
  fetchCustomer,
) => {
  const mutation = useMutation(cancelStripeSub, {
    onSuccess: () => {
      addMessage('Subscription canceled', 'success');
      setShowCancelSubscription(() => false);
      fetchCustomer();
    },
    onError: () => {
      addMessage('Something went wrong while canceling subscription', 'error');
    },
  });

  return mutation;
};
