import Android1 from './images/Android_1_150_PL.png';
import Android2 from './images/android_2.png';
import Ios1 from './images/IOS_1_150_PL.png';
import Ios2 from './images/ios_2.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';

export default {
  passwordRestart: {
    title: 'Resetuj hasło',
    password: 'Hasło',
    repeatPassword: 'Powtórz hasło',
    restartPawssord: 'Zresetuj hasło',
  },
  paywall: {
    title: 'Wybierz plan, aby kontynuować naukę',
    buy: 'Kup',
    savePayment: 'Bezpieczne płatności obsługuje',
    skip: 'Pomiń',
    pickPlan: 'Wybierz plan',
    endPlanFirstPart: 'Twój dostęp zakończy się za',
    endPlanSecondPart: 'dni.',
    endPlanAditionalPart: 'dzień.',
  },
  paymentStatus: {
    veryfication: 'Twoja płatność jest jeszcze procesowana... ',
    success: 'Płatność zakończona sukcesem!',
    failure: 'Płatność zakończona niepowodzeniem!',
    subtitle: 'Zachwilę zostaniesz przekierowany na stronę logowania.',
    textInfo: `Twoja płatność jest w trakcie przetwarzania. W ciągu kilku minut otrzymasz wiadomość e-mail z hasłem rejestracyjnym. Prosimy o sprawdzenie skrzynki odbiorczej, a także folderu spam. Aby ułatwić wyszukiwanie, najlepiej wpisać w polu wyszukiwania słowo "synthiai". W przypadku jakichkolwiek problemów prosimy o kontakt pod adresem: contact@synthiaiapp.com`,
    login: 'Zaloguj się',
  },
  register: {
    steps: {
      register: 'Rejestracja',
      package: 'Pakiet',
      summarize: 'Podsumowanie',
      step: 'Krok ',
    },
    stepOne: {
      password: 'Hasło',
      errorGoogle: 'Zaloguj się uywając maila',
      title: 'Rejestracja',
      nameAndSurname: 'Imię i Nazwisko',
      placeholderNameAndSurname: 'np. Adam Smith',
      email: 'Email',
      placeholderEmail: 'np. example@synthiaiapp.com',
      password: 'Hasło',
      start: 'UTWÓRZ KONTO',
      subtitle:
        'Hasło musi zawierać co najmniej 8 znaków, w tym małe i duże litery oraz cyfrę.',
      passwordError1: 'Hasło musi zawierać co najmniej 8 znaków.',
      passwordError2: 'Hasło musi zawierać co najmniej 1 wielka litere.',
      passwordError3: 'Hasło musi zawierać co najmniej 1 mała litere.',
      passwordError4: 'Hasło musi zawierać co najmniej 1 liczbę.',
    },
    stepTwo: {
      title: 'Wybierz plan dla siebie',
      month: 'miesiąc',
      months: 'miesiące',
      months2: 'miesięcy',
      value: 'zł/mies',
      pick: 'wybierz',
      nowPay: 'Teraz zapłacisz',
      saveOne: 'Oszczędzasz',
      buy: 'Kup',
      savePayment: 'Bezpieczne płatności obsługuje',
    },
    stepThree: {
      title: 'Podsumowanie',
      product: 'Produkt:',
      plan: 'Synthi Ai Pakiet',
      forLong: 'Dostęp na',
      data: 'dane:',
      nameAndSurname: 'Imie i nazwisko:',
      paymentForm: 'Forma płatności:',
      toPay: 'Do zapłaty dzisiaj:',
      accept: 'Akceptuję',
      rules: 'regulamin',
      payingAccept: 'Płacąc akceptujesz',
      paymentRules: '"Zasady płatności PayU"',
      buyAndPay: 'Zapłać',
    },
  },
  login: {
    title: 'Logowanie',
    email: 'Email',
    password: 'Hasło',
    textSub: 'Nie pamiętasz hasła? Zresetuj',
    restart: 'tutaj',
    restartPasswordText:
      'Wpisz swojego maila aby zresetowac haslo. Email z hasłem przyjdzie na twoja skrzyke pocztowa',
    placeholder: 'wpisz swojego maila...',
    resetPassword: 'zresetuj hasło',
    buttonText: 'Zaloguj',
    errorLogin: 'Sprawdź czy twoje hasło lub email są prawidłowe',
    passwordResterted: 'Twoje hasło zostało zresetowane sprawdź maila.',
    passwordError: 'Niestety nie mogliśmy znaleźć twojego maila.',
  },
  navBar: {
    home: 'Start',
    learn: 'Ucz się',
    profile: 'Profile',
  },
  home: {
    hello: 'Cześć',
    question: 'O czym dzisiaj porozmawiamy?',
    answer: 'Rozpocznij rozmowę',
    forYou: 'Polecane dla Ciebie',
    popularNow: 'Popularne teraz',
    doYouKnow: 'Czy wiesz że...?',
    funFact: 'Ciekawostka dnia',
    new: 'Nowość',
    newTitle: 'Telefon',
    newSubtitle: 'Zadzwoń do',
    yourFavorite: 'Twoje ulubione',
  },
  learn: {
    chat: {
      title: 'Czat',
      subtitle: 'Rozmawiaj na dowolny temat.',
    },
    callMode: {
      title: 'Telefon',
      subtitle: 'Rozmawiaj w czasie rzeczywistym.',
      new: 'Nowość',
      transcriptionError:
        'Ups, coś poszło nie tak z twoim nagraniem. Spróbuj jeszcze raz.',
    },
    fotoMode: {
      title: 'Foto zagadka',
      subtitle: 'Opisz obrazek jak najdokładniej.',
    },
    scene: {
      title: 'Scenki',
      subtitle: 'Odgrywaj rolę z AI tutorem.',
      // new: 'Nowość',
    },
    word: {
      title: 'Słowa',
      subtitle: 'Poszerzaj swoje słownictwo.',
    },
    flashcard: {
      title: 'Fiszki',
      subtitle: 'Powtarzaj i zapamiętuj słowa.',
    },
    seeMore: 'Zobacz wszystkie',
  },
  common: {
    description:
      'Synthi Ai - Twój Inteligentny Asystent do nauki angielskiego. Ucz się płynnie rozmawiać po angielsku z pomocą sztucznej inteligencji. Odkryj nowe, efektywne podejście do nauki języka. Zacznij już dziś!',
    deletedFromFavorite: 'Usunieto z ulubionych',
    addedToFavorite: 'Dodano do ulubionych',
    flashcards: 'fiszki',
    scenes: 'Scenki',
    words: 'Słowa',
    or: 'lub',
    continueWithFB: 'Kontynuuj z Facebook',
    privacyLink: '/Privacy_PL.pdf',
    termsLink: '/Terms_Conditions_PL.pdf',
  },
  profile: {
    days: ['Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'Sb', 'Nd'],
    top: {
      profile: 'Profil',
      accessTo: 'Dostęp do:',
      profileImage: 'Zdjęcie profilowe',
      nameAndSurname: 'Imię i nazwisko',
      password: 'Hasło',
      change: 'Zmień',
      changeProfileImage: 'Zmień zdjęcie profilowe',
      changeNameAndSurname: 'Zmień swoje imię i nazwisko',
      changeNameAndSurnamePlaceHolder: 'Wpisz imię i nazwisko',
      save: 'Zapisz',
      changePassword: 'Zmień swoje hasło',
      passwordInfo:
        'Hasło musi zawierać co najmniej 8 znaków, w tym małe i duże litery oraz cyfrę',
      repeatPassword: 'Powtórz hasło',
      passwordChanged: 'Hasło zmieone',
      error: 'Ups coś poszło nie tak',
      nameChanged: 'Name changed',
      profileChanged: 'Profile Changed',
    },
    plan: {
      subInfo: '',
      yourPlan: 'Twój plan',
      extend: 'Przedłuż swój plan',
      short_extend: 'Przedłuż',
      currentPlan: 'Obecny plan:',
      activeToPlan: 'Aktywny do:',
      currentPlanLong: 'Twoj obecny plan',
    },
    middle: {
      yourMedals: 'Twoje osiągnięcia',
      oneDay: 'dzień',
      twoDays: 'dni',
      words: 'Słowa',
      learningTime: 'Czas nauki',
      streak: 'Streak',
      streakRecord: 'Streak rekord',
      yourRecord: 'Twój rekord:',
      currentRecord: 'Aktualny wynik:',
      words: 'Słowa',
      wordsInWeek: 'Liczba słów w tygodniu:',
    },
    bottom: {
      extend: 'Przedłuż swój plan',
      yourTutor: 'Twój Ai tutor',
      change: 'Zmień',
      logout: 'Wyloguj się',
      FAQ: 'FAQ',
      shareOpinion: 'Podziel się opinią',
      hobby: 'Zainteresowania',
      learningSetting: 'Ustawienia nauki',
      back: 'Wstecz',
      dayTarget: 'Dzienny cel',
      languageLevel: 'Poziom języka',
      changeYourLangLevel: 'Zmień swój poziom języka.',
      pickNewTutor: 'Wybierz nowego tutora',
      yourPlan: 'Twój plan',
      currentPlan: 'Obecny plan:',
      activeToPlan: 'Aktywny do:',
      yourDecision: 'Twoja decyzja wpłynie na styl i atmosferę lekcji.',
      tutroChanged: 'Zmieniono tutora',
      error: 'Ups coś poszło nie tak',
      englishLevelChanged: 'Twój poziom został zmieniony',
      levelsOptions: [
        {
          name: 'Początkujący A1',
          description: 'Rozpoczynasz swoją przygodę z językiem angielskim',
          value: 'basic',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Podstawowy A2',
          description:
            'Potrafisz zrozumieć podstawowe zwroty przekazywane w prostych dialogach.',
          borderRadius: '0',
          value: 'beginner',
        },
        {
          name: 'Średnio-zaawansowany B1',
          description:
            'Prowadzisz proste, ale zrozumiałe rozmowy na znane tematy.',
          borderRadius: '0',
          value: 'intermediate',
        },
        {
          name: 'Zaawansowany B2',
          description: 'Porozumiewasz się swobodnie w większości sytuacji',
          borderRadius: '0',
          value: 'advanced',
        },
        {
          name: 'Ekspert C1+',
          description:
            'Posługujesz się językiem efektywnie w skomplikowanych kontekstach',
          value: 'expert',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      changeDailyTarget: 'Zmień dzienny cel nauki.',
      deletedFromFavorite: 'Usunięto z zainteresowań',
      addedToFavorite: 'Dodano do zainteresowań',
      lessonTime: [
        {
          name: 'Szybka lekcja ',
          dsecription: 'Idealna opcja dla ekspresowej nauki',
          time: '5 min',
          value: 'five_minutes',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Standardowa sesja',
          dsecription: 'Świetny wybór dla zbilansowanej nauki',
          time: '10 min',
          value: 'ten_minutes',
          borderRadius: '0',
        },
        {
          name: 'Intensywna praktyka',
          dsecription: 'Najlepsza propozycja dla entuzjastów',
          time: '15 min',
          value: 'fifteen_minutes',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      dailyTargetChanged: 'Dzienny cel zmieniony!',
      pickIntresets: 'Wybierz minimum 3 zainteresowania.',
      activeTo: 'Plan aktywny do:',
      daysLeft: 'Ilość dni do końca planu:',
      opinionSubtitle:
        'Nieustannie ulepszamy naszą aplikację. Podziel się swoją opinią o tym, co możemy zmienić w aplikacji lub co podoba Ci się najbardziej.',
      startTyping: 'Zacznij pisać',
      faq: 'FAQ',
      send: 'Wyślij',
      withProblems: 'W przypadku pytań/problemów skontaktuj się z nami:',
      contactMail: 'bok@SynthiAiapp.com',
      faqConfig: [
        {
          id: 1,
          question: 'Nie działa mi dźwięk.',
          answer: `W przypadku problemów z dźwiękiem rozważ następujące porady: 
      
              Sprawdź uprawnienia dźwięku w przeglądarce.
      
              Upewnij się, że głośność urządzenia jest odpowiednia.
      
              Sprawdź poprawne podłączenie głośników/słuchawek.
      
              Przejrzyj ustawienia dźwięku systemu, aby sprawdzić, czy aplikacje nie są wyciszone.
      
              Wypróbuj inne urządzenie lub skontaktuj się z pomocą techniczną.
          `,
        },
        {
          id: 2,
          question: 'Nie mogę nagrać swojego głosu.',
          answer: `W przypadku problemów z nagrywaniem dźwięku rozważ następujące porady: 
              
              Sprawdź uprawnienia mikrofonu w przeglądarce.
      
              Upewnij się, że przeglądarka mobilna ma dostęp do mikrofonu.
      
              Sprawdź poprawne podłączenie mikrofonu.
      
              Rozważ użycie innego urządzenia lub skontaktuj się z pomocą techniczną.
          `,
        },
        {
          id: 3,
          question:
            'Czy mogę korzystać z aplikacji na więcej niż jednym urządzeniu?',
          answer: `Oczywiście, po prostu zaloguj się na dowolnym kompatybilnym urządzeniu, aby cieszyć się płynnym doświadczeniem w nauce.`,
        },
        {
          id: 4,
          question: 'Czy mogę używać Synthi Ai offline?',
          answer: `Nie, Synthi Ai wymaga przeglądarki internetowej z dostępem do internetu.
          `,
        },
        {
          id: 5,
          question: 'Jak zmienić tutora AI?',
          answer: `Przejdź do zakładki "Tutor" w ustawieniach Synthi Ai. Eksploruj i wybierz preferowanego tutora.
          `,
        },
        {
          id: 6,
          question: 'Jak przedłużyć dostęp do aplikacji po wygaśnięciu?',
          answer: `Możesz odnowić swój plan po zalogowaniu się po wygaśnięciu.
          `,
        },
        {
          id: 7,
          question: 'Czy mogę otrzymać fakturę?',
          answer: `Skontaktuj się z bok@SynthiAiapp.com podając NIP i dane firmy.
          `,
        },
        {
          id: 8,
          question: 'Jak mogę skontaktować się z pomocą techniczną?',
          answer: `Wyślij e-mail na adres bok@SynthiAiapp.com podając szczegóły problemu, informacje o urządzeniu i przeglądarce.
          `,
        },
      ],
    },
    rules: 'regulamin',
    privacy: 'Polityka prywatności',
    span: ' i ',
  },
  welcome: {
    languagePicker: {
      title: 'Wybierz język jakiego chcesz się uczyć',
      next: 'Dalej',
      langToLearn: 'Język nauki',
      successTitle: 'Język nauki został zmieniony',
      config: [
        {
          label: 'Angielskiego',
          label2: 'Angielski',
          labelInside: 'Angielskim',
          value: 'en',
        },
        {
          label: 'Niemieckiego',
          label2: 'Niemiecki',
          labelInside: 'Niemieckim',
          value: 'de',
        },
        {
          label: 'Hiszpańskiego',
          label2: 'Hiszpański',
          labelInside: 'Hiszpańskim',
          value: 'es',
        },
        {
          label: 'Francuskiego',
          label2: 'Francuski',
          labelInside: 'Francuskim',
          value: 'fr',
        },
        {
          label: 'Włoskiego',
          label2: 'Włoski',
          labelInside: 'Włoskim',
          value: 'it',
        },
      ],
    },
    zeroScreen: {
      welcomeInApp: 'Witamy w aplikacji Synthi AI!',
      start: 'ZACZYNAMY',
      welcomeMobile: 'Witamy',
      config: {
        chrome: {
          title: 'Zainstaluj aplikację na urządzeniu z Androidem.',
          text1: 'W przeglądarce Chrome kliknij przycisk',
          text1bold: ' Ustawienia',
          image1: Android1,
          text2: 'Następnie kliknij',
          text2bold: ' Dodaj do ekranu głównego',
          image2: Android2,
          text3: 'Gotowe! Synthi Ai znajdziesz teraz na ekranie głównym',
          image3: Last,
        },
        safari: {
          title: 'Zainstaluj aplikację na urządzeniu z iOS.',
          text1: 'W przeglądarce Safari kliknij przycisk',
          text1bold: ' Udostępnij',
          image1: Ios1,
          text2: 'Następnie kliknij',
          text2bold: ' Dodaj do ekranu głównego',
          image2: Ios2,
          text3: 'Gotowe! Synthi Ai znajdziesz teraz na ekranie głównym',
          image3: Last,
        },
      },
    },
    firstScreen: {
      title: 'Wybierz swojego tutora!',
      subtitle: 'Twoja decyzja wpłynie na styl i atmosferę lekcji.',
      next: 'DALEJ',
    },
    secondScreen: {
      title: 'Jaki jest Twój obecny poziom {language}?',
      config: [
        {
          name: 'Początkujący A1',
          description: 'Rozpoczynasz swoją przygodę z językiem {language}.',
          value: 'basic',
        },
        {
          name: 'Podstawowy A2',
          description:
            'Potrafisz zrozumieć podstawowe informacje przekazywane w prostych, codziennych dialogach',
          value: 'beginner',
        },
        {
          name: 'Średnio-zaawansowany B1',
          description:
            'Prowadzisz proste, ale zrozumiałe rozmowy na znane tematy.',
          value: 'intermediate',
        },
        {
          name: 'Zaawansowany B2',
          description: 'Porozumiewasz się swobodnie w większości sytuacji',
          value: 'advanced',
        },
        {
          name: 'Ekspert C1+',
          description:
            'Posługujesz się językiem efektywnie w skomplikowanych kontekstach',
          value: 'expert',
        },
      ],
      next: 'DALEJ',
    },
    thirdScreen: {
      title: 'Jakie są Twoje zainteresowania?',
      subtitle: 'Wybierz minimum 3 zainteresowania.',
      next: 'DALEJ',
    },
    fourthScreen: {
      title: 'Ile minut dziennie chcesz się uczyć?',
      subtitle: 'Wybierz swój dzienny cel.',
      next: 'ZACZYNAMY!',
      config: [
        {
          name: 'Szybka lekcja ',
          dsecription: 'Idealna opcja dla ekspresowej nauki',
          time: '5 min',
          value: 'five_minutes',
        },
        {
          name: 'Standardowa sesja',
          dsecription: 'Świetny wybór dla zbilansowanej nauki',
          time: '10 min',
          value: 'ten_minutes',
        },
        {
          name: 'Intensywna praktyka',
          dsecription: 'Najlepsza propozycja dla entuzjastów',
          time: '15 min',
          value: 'fifteen_minutes',
        },
      ],
    },
  },
  features: {
    topbar: {
      back: 'Wstecz',
    },
    fotoMode: {
      welcome: {
        title: 'Foto zagadka',
        subtitle:
          'Opisz co widzisz na obrazku w wybranym temacie jak największą ilościąszczegółów.',
        start: 'Start !',
      },
      info: {
        startTyping: 'Zacznij pisać...',
        newTask: 'Nowe zadanie',
        send: 'Wyślij',
      },
    },
    flashcards: {
      title: 'Fiszki',
      subtitleTop:
        'Poznawaj i zapamiętuj nowe słowa dzięki interaktywnej formie nauki z fiszkami.',
      addCategory: 'Dodaj swoją kategorię',
      subtitle: 'Wpisz nazwę swojej kategorii',
      type: 'wpisz kategorię...',
      add: 'Dodaj',
      previous: 'Poprzednia fiszka',
      task: 'Zadanie',
      changeLevel: 'Zmień poziom',
      dontKnow: 'Nie wiem',
      know: 'Wiem',
      pickPairs: 'Połącz w pary',
      goodJob: 'Dobra robota!',
      titleEnd: 'Twoje zaangażowanie robi wrażenie',
      subtitleEnd: 'Czy jesteś gotowy na więcej wyzwań?',
      repeat: 'Powtórz kategorię',
      other: 'Wybierz nową kategorię',
      level: {
        title: 'Zmień poziom karty fiszek',
        beginner: ' A1-A2 beginner',
        intermediate: 'B1-B2 intermediate',
        advance: 'C1-C2 advance',
        change: 'Zmień',
      },
    },
    scene: {
      title: 'Scenki',
      subtitle:
        'Wciel się w rolę i odegraj scenkę z AI tutorem na wybrany temat.',
      suggest: 'Zaproponuj nową scenkę',
      talk: 'Rozmawiaj',
      deletedFromFavorite: 'Usunieto z ulubionych',
      addedToFavorite: 'Dodano do ulubionych',
    },
    word: {
      title: 'Słowa',
      subtitle:
        'Rozszerzaj zasób słownictwa w kluczowych obszarach w rozmowie z AI tutorem.',
      suggest: 'Zaproponuj nowy temat słów',
      deletedFromFavorite: 'Usunieto z ulubionych',
      addedToFavorite: 'Dodano do ulubionych',
    },
  },
  cookie: {
    title: 'Informacja o plikach cookies',
    subtitle:
      'Ta strona wykorzystuje pliki cookie, co pozwala nam zapewnić lepsze wrażenia z użytkowania oraz dostarczyć spersonalizowane treści. Jeśli chcesz dowiedzieć się więcej na ten temat, zapoznaj się z naszą ',
    link: 'polityką prywatności',
    accept: 'AKCEPTUJ',
    denied: 'ODRZUĆ',
  },
  talkingBar: {
    allowMicrofon: 'Proszę zezwolić na dostęp do mikrofonu',
    typeMessage: 'Napisz wiadomość…',
    record: 'Nagraj',
    recording: 'Nagrywanie',
  },
  hands: {
    giveUsInfo: 'Daj znać co myślisz',
    thanksForGrading: 'Dziękujemy za ocenę!',
    subtitle: 'Twój głos jest dla nas ważny!',
    subtitle2: 'Powiedz nam, co myślisz o tej lekcji:',
    skip: 'POMIŃ',
    send: 'WYŚLIJ',
  },
  suggestConfig: {
    profile: {
      title: 'Podziel się opinią!',
      subtitle:
        'Nieustannie ulepszamy naszą aplikację. Podziel się swoją opinią o tym, co możemy zmienić w aplikacji lub co podoba Ci się najbardziej.',
    },
    word: {
      title: 'Zaproponuj nowy temat słów',
      subtitle: 'Tekst tekst tekst',
    },
    scene: {
      title: 'Zaproponuj nową scenkę',
      subtitle: 'Tekst tekst tekst',
    },
    startTyping: 'Zacznij pisać',
    send: 'WYŚLIJ',
    thx: 'Dodano opinie! Dziękujemy!',
    error: 'Ups coś poszło nie tak',
  },
  checkout: {
    leftTitle: 'Wybierz plan dla siebie',
    rightTitle: 'Uzupełnij dane',
    email: 'Email',
    placeHolderEmail: 'Wpisz email',
    pay: 'KUPUJĘ I PŁACĘ',
    accept: 'Akceptuję',
    rules: 'regulamin',
    payingAccept: 'Płacąc akceptujesz',
    paymentRules: '"Zasady płatności PayU"',
    savePayments: 'Bezpieczne płatności obsługuje',
    total: 'Razem',
    currency: 'pln',
    currencySign: 'zł',
    emailGuessText: 'Czy miałeś na myśli',
  },
  dailyV2: {
    hint: {
      title: 'Podpowiedź',
      pick: 'Wybierz',
    },
    feedback: {
      yourMessage: 'Twoja wiadomość',
      goodMessage: 'Poprawna wiadomość',
      explanation: 'Wyjaśnienie',
    },
    tutorial: {
      title: 'Jak korzystać z czata?',
      skip: 'Pomiń',
      next: 'DALEJ',
      start: 'Rozpocznij',
      screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
      config: [
        [
          { title: 'Kliknij, aby wyciszyć dźwiek Tutora', image: VolumeUp },
          { title: 'Kliknij, aby podzielić się opinią', image: HandsUp },
          { title: 'Kliknij, aby otworzyć instrukcję', image: Tutorial },
        ],
        [
          { title: 'Kliknij, aby odsłuchać ponownie', image: VolumeUpBlank },
          {
            title: 'Kliknij, aby zobaczyć tłumaczenie',
            image: TranslationBlank,
          },
          { title: 'Kliknij, aby zobaczyć podpowiedź', image: HintBlank },
          { title: 'Kliknij, aby zgłosić problem', image: InfoBlank },
        ],
        [
          { title: 'Twoja wypowiedź jest poprawna', image: GreenCircle },
          {
            title:
              'Kliknij, aby zobaczyć poprawioną wypowiedź oraz wyjaśnienie',
            image: YellowCircle,
          },
        ],
        [
          { title: 'Kliknij w pole tekstowe, aby napisać  wiadomość ' },
          { title: 'Kliknij, aby nagrać wiadomość', image: Microphone },
        ],
      ],
    },
  },
  problem: {
    title: 'Zgłoś problem',
    subtitle: 'Opisz problem dotyczący wiadomości AI',
    send: 'Wyślij',
    messageSend: 'Wiadomość wysłana',
    error: 'Coś poszło nie tak napisz do nas :)',
  },
};
