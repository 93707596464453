import React from 'react';
import { Card, Title, InfoWrapper, P } from '../styles';
import { formatDate } from '@/pages/admin/mail/AdminMail';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import CustomDeleteButton from '@/common/components/custom/buttons/CustomDeleteButton';

const OrderDetails = ({ order, setEditOrder, payments = [], setCancel }) => {
  return (
    <>
      <Card>
        <Title>
          Order:{' '}
          <div>
            <CustomEditButton onClick={() => setEditOrder(order)} />
            {order?.sub_id && !order?.subscription_canceled_at && (
              <CustomDeleteButton
                text={'Cancel Plan'}
                onClick={() => setCancel(order)}
              />
            )}
          </div>
        </Title>
        <InfoWrapper>
          <P>
            <p>Plan:</p>
            <b>{order.plan_type || '------'}</b>
          </P>
          <P>
            <p>Price:</p>
            <b>{(order.price / 100).toFixed(2) || '------'} zł</b>
          </P>
          <P>
            <p>Created At:</p>
            <b>{formatDate(order.created_at) || '------'}</b>
          </P>
          <P>
            <p>Expired At:</p>
            <b>{formatDate(order.expired_date) || '------'}</b>
          </P>
          <P>
            <p>Payment Type:</p>
            <b>{order.payment_type || '------'}</b>
          </P>
          {order?.subscription_canceled_at && (
            <P>
              <p>Cancel At:</p>
              <b>{formatDate(order.subscription_canceled_at) || '------'}</b>
            </P>
          )}
        </InfoWrapper>
      </Card>
    </>
  );
};

export default OrderDetails;
