import React, { useRef, useState } from 'react';
import { styled } from 'styled-components';
import { Color } from '@/common/colors/colors';
import moment from 'moment';
import Input from '@/common/components/custom/Input';
import SelectInput from '@/common/components/custom/SelectInput';

const SelectBarContainer = styled.form`
  border-radius: 10px;
  background-color: ${Color.White};
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border: 1px dashed grey;
`;

const SearchButton = styled.button`
  padding: 10px 20px;
  background-color: ${Color.DarkBlue};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-left: 20px;

  &:hover {
    background-color: ${Color.Grey};
  }
`;

const SearchBar = ({ handleSearch, countriesOptions }) => {
  const [selectedCountries, setSelectedCountries] = useState();
  const fromRef = useRef();
  const toRef = useRef();

  const handleSearchCheck = (e) => {
    e?.preventDefault();

    const payload = {};

    payload.from = moment(fromRef.current.value).format('YYYY-MM-DD');
    payload.to = moment(toRef.current.value).format('YYYY-MM-DD');
    payload.country = selectedCountries.value;

    handleSearch(payload);
  };

  return (
    <>
      <SelectBarContainer onSubmit={handleSearchCheck}>
        <SelectInput
          name={'Country'}
          options={countriesOptions}
          selected={selectedCountries}
          setSelected={setSelectedCountries}
          width={100}
          selectWidth={150}
        />
        <Input
          type="date"
          inputRef={fromRef}
          name="From"
          inputWidth={120}
          width={100}
          required={true}
          value={moment().subtract('4', 'week').format('YYYY-MM-DD')}
        />
        <Input
          type="date"
          inputRef={toRef}
          name="To"
          inputWidth={120}
          width={100}
          required={true}
          value={moment().format('YYYY-MM-DD')}
        />
        <SearchButton type="submit">Search</SearchButton>
      </SelectBarContainer>
    </>
  );
};

export default SearchBar;
