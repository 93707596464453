import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Wrapper } from './learn.styles';
import styled from 'styled-components';
import TopBar from '../home/components/TopBar';
import Chat from './components/Chat';
import Scene from './components/Scene';
import { useMutation } from 'react-query';
import { AddToFavorite, findUserInfoForLearn } from '@/api/query/customer';
import Words from './components/Words';
import Flashcards from './components/Flashcards';
import { MessageContext } from '@/common/contexts/MessageContext';
import ImageMode from './components/ImageMode';
import Loading from '@/common/components/Loading';
import ChatDesktop from './desktop/ChatDesktop';
import ImageModeDesktop from './desktop/ImageModeDesktop';
import SceneDesktop from './desktop/SceneDesktop';
import WordsDesktop from './desktop/WordsDesktop';
import FlashcardsDesktop from './desktop/FlashcardsDesktop';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import CallModeDesktop from './desktop/CallModeDesktop';
import CallMode from './components/CallMode';
import { CustomerDataContext } from '@/common/components/layout/productLayout/context/CustomerDataContext';

const CurrentWrapper = styled(Wrapper)`
  @media (min-width: 600px) {
    padding-top: 30px;
    padding-bottom: 30px;
    overflow-y: auto;
  }

  @media (max-width: 600px) {
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const Mobile = styled.div`
  @media (min-width: 800px) {
    display: none;
  }
`;

const Desktop = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const Learn = () => {
  const {
    learnData,
    handleLoadLearnDataMutation,
    manageFavoriteMutation,
    favoriteArr,
  } = useContext(CustomerDataContext);

  const handleAddToFavorite = (id, type) => {
    const payload = {
      _id: id,
      type,
      shouldAdd: favoriteArr?.includes(id) ? false : true,
    };

    manageFavoriteMutation.mutate(payload);
  };

  useEffect(() => {
    window.document.title = 'Synthi Ai - Learn';
  }, []);

  return (
    <>
      {(handleLoadLearnDataMutation.isLoading ||
        manageFavoriteMutation.isLoading) && <Loading />}
      <CurrentWrapper>
        <Mobile>
          <TopBar
            numberTwo={learnData?.stats?.wordsNumber}
            numberOne={learnData?.streak}
          />
          <Chat />
          <Scene
            data={learnData?.scenes}
            handleAddToFavorite={handleAddToFavorite}
            liked={favoriteArr}
          />
          <CallMode />
          <Words
            data={learnData?.words}
            handleAddToFavorite={handleAddToFavorite}
            liked={favoriteArr}
          />
          <ImageMode />
          <Flashcards
            data={learnData?.flashcards}
            handleAddToFavorite={handleAddToFavorite}
            liked={favoriteArr}
          />
        </Mobile>
        <Desktop>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '32px',
            }}
          >
            <ChatDesktop />
            <ImageModeDesktop />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              gap: '32px',
            }}
          >
            <CallModeDesktop />
          </div>
          <SceneDesktop
            data={learnData?.scenes}
            handleAddToFavorite={handleAddToFavorite}
            liked={favoriteArr}
          />

          <WordsDesktop
            data={learnData?.words}
            handleAddToFavorite={handleAddToFavorite}
            liked={favoriteArr}
          />
          <FlashcardsDesktop
            data={learnData?.flashcards}
            handleAddToFavorite={handleAddToFavorite}
            liked={favoriteArr}
          />
        </Desktop>
      </CurrentWrapper>
    </>
  );
};

export default Learn;
