import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import CustomTable from '@/common/components/custom/CustomTable';
import { useMutation } from 'react-query';
import Loading from '@/common/components/Loading';
import moment from 'moment';
import { getOpinions } from '@/api/query/opinion';

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const HEADERS = ['No.', 'Created at', 'By', 'Country', 'Message', 'Type'];

export const formatDate = (d) => {
  if (!d) {
    return '------';
  }

  return moment(d).format('DD/MM/YYYY HH:mm');
};

const AdminOpinions = () => {
  const [tableData, setTableData] = useState();

  const findAllOpinonsMutation = useMutation({
    mutationFn: () => getOpinions(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data
          ?.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime(),
          )
          .map((d, i) => [
            i + 1,
            formatDate(d.created_at),
            d.user.email,
            `${d.user?.country?.short}-${d.user?.language}`,
            d.message || '-----',
            d.type,
          ]),
      );
    },
  });

  useEffect(() => {
    findAllOpinonsMutation.mutate();
  }, []);

  return (
    <>
      {findAllOpinonsMutation.isLoading && <Loading />}
      <Wrapper>
        <TableWrapper>
          <CustomTable headers={HEADERS} data={tableData} csvData={tableData} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminOpinions;
