import { Color } from '@/common/colors/colors';
import CustomDeleteButton from '@/common/components/custom/buttons/CustomDeleteButton';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import Input from '@/common/components/custom/Input';
import SelectInput from '@/common/components/custom/SelectInput';
import PopUp from '@/common/components/PopUp';
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import SearchFilter from './SearchFilter';
import { useMutation } from 'react-query';
import { getAllPosibleFiledsToFilter } from '@/api/query/report';
import { createFilter, updateFilter } from '@/api/query/filter';
import { MessageContext } from '@/common/contexts/MessageContext';

const Wrapper = styled.div`
  border-radius: 10px;
  background-color: ${Color.White};
  padding: 10px 20px;
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
  border: 1px dashed grey;
  max-width: 550px;
`;

const OptionsWrapper = styled.div`
  width: 1200px;
  height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Title = styled.h3``;

const FormSaving = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TABLE_FILEDS_OPTIONS = [
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
  { label: 'Plan', value: 'order.plan_type' },
  { label: 'Price', value: 'order.price' },
  { label: 'Is call mode pop-up show', value: 'isCallModePopUpShow' },
  { label: 'Created at', value: 'created_at' },
  { label: 'Ad source', value: 'adSource' },
  { label: 'Daily minutes', value: 'dailyMinutes' },
  { label: 'Total cost', value: 'totalCost' },
  { label: 'Page URL', value: 'pageUrl' },
  { label: 'Current streak', value: 'currentStreak' },
  { label: 'Max streak', value: 'maxStreak' },
  { label: 'Seconds in app', value: 'secondsInApp' },
  { label: 'UTM campaign', value: 'utm.utm_campaign' },
  { label: 'UTM medium', value: 'utm.utm_medium' },
  { label: 'UTM source', value: 'utm.utm_source' },
  { label: 'UTM content', value: 'utm.utm_content' },
  { label: 'UTM term', value: 'utm.utm_term' },
  { label: 'UTM fbclid', value: 'utm.utm_fbclid' },
  { label: 'Msclkid', value: 'utm.msclkid' },
  { label: 'Guess UTM campaign', value: 'guess_utm.utm_campaign' },
  { label: 'Guess page name', value: 'guess_utm.pageName' },
  { label: 'Guess UTM medium', value: 'guess_utm.utm_medium' },
  { label: 'Guess UTM source', value: 'guess_utm.utm_source' },
  { label: 'Guess UTM content', value: 'guess_utm.utm_content' },
  { label: 'Guess UTM term', value: 'guess_utm.utm_term' },
  { label: 'Guess UTM fbclid', value: 'guess_utm.utm_fbclid' },
  { label: 'Guess msclkid', value: 'guess_utm.msclkid' },
  { label: 'Daily words', value: 'dailyWords' },
  { label: 'Hobby', value: 'hobby' },
  { label: 'Level', value: 'level' },
  { label: 'Speaker', value: 'speaker' },
  { label: 'Native language', value: 'nativeLanguage' },
  { label: 'Account type', value: 'accountType' },
  { label: 'Chat configs', value: 'chatConfigs' },
  { label: 'Country', value: 'country' },
];

function reducer(state, action) {
  if (action.type === 'add')
    return {
      ...state,
      [action.key]: {
        ...(action.value && {
          value:
            action.type_field === 'Number'
              ? parseInt(action.value)
              : action.value,
        }),
        ...(action.arithmetic && { arithmetic: action.arithmetic }),
        ...(action.from && { from: action.from }),
        ...(action.to && { to: action.to }),
        ...(action.arithmetic_from && {
          arithmetic_from: action.arithmetic_from,
        }),
        ...(action.arithmetic_to && { arithmetic_to: action.arithmetic_to }),
        ...(action.isTrue && { isTrue: action.isTrue }),
        ...(action.type_field && { type_field: action.type_field }),
        ...(action.from_days && { from_days: parseInt(action.from_days) }),
        ...(action.from_hours && { from_hours: parseInt(action.from_hours) }),
        ...(action.to_days && { to_days: parseInt(action.to_days) }),
        ...(action.to_hours && { to_hours: parseInt(action.to_hours) }),
        ...(action.enumValues && { enumValues: action.enumValues }),
      },
    };

  if (action.type === 'validate') {
    const result = {};

    action.values.map((value) => {
      if (state[value.label]) {
        result[value.label] = state[value.label];
      }
    });

    return {
      ...result,
    };
  }

  if (action.type === 'remove') {
    delete state[action.key];
    return {
      ...state,
    };
  }

  if (action.type === 'init') {
    return {
      ...action.data,
    };
  }
}

const Search = ({
  savedFilters,
  handleSearch,
  admins,
  nativeLanguages,
  headers,
  setHeaders,
  hobbies,
  speakers,
  chatConfigs,
  countries,
  reload,
}) => {
  const [filters, dispatchFilters] = useReducer(reducer, {});
  const [selectedData, setSelectedData] = useState([]);
  const [selectedShared, setSelectedShared] = useState([]);

  const [selectedFlter, setSelectedFilter] = useState();
  const [options, setOptions] = useState();
  const [data, setData] = useState();

  const { addMessage } = useContext(MessageContext);

  const nameInputRef = useRef();

  const triggerHandleSearch = () => {
    const payload = {};
    payload.filters = filters;
    handleSearch(payload);
  };

  const handleCreateFilterMutation = useMutation({
    mutationFn: (data) => createFilter(data),
    onSuccess: ({ data }) => {
      addMessage('Created', 'success');
      reload();
    },
    onError: () => {
      addMessage('Error', 'error');
    },
  });

  const handleUpdateFilterMutation = useMutation({
    mutationFn: (data) => updateFilter(data),
    onSuccess: ({ data }) => {
      addMessage('Updated', 'success');
      reload();
    },
    onError: () => {
      addMessage('Error', 'error');
    },
  });

  const triggerHandleSaveFilters = (e) => {
    e?.preventDefault();

    const payload = { ...(selectedFlter?.value || {}) };
    payload.data = filters;
    payload.name = nameInputRef.current.value;
    payload.fields = headers;
    payload.shared_for = selectedShared.map((s) => s.value);

    if (!payload?._id) {
      return handleCreateFilterMutation.mutate(payload);
    }

    handleUpdateFilterMutation.mutate(payload);
  };

  const getAllPosibleFiledsToFilterMutation = useMutation({
    mutationFn: () => getAllPosibleFiledsToFilter(),
    onSuccess: ({ data }) => {
      console.log(data.orderFields);
      const toSetUp = [
        ...Object.entries(data.customerFields).map(([key, value]) => {
          return { label: 'customer.' + key, value: value };
        }),
        ...Object.entries(data.orderFields).map(([key, value]) => {
          return { label: 'order.' + key, value: value };
        }),
        ...Object.entries(data.paymentFields).map(([key, value]) => {
          return { label: 'payment.' + key, value: value };
        }),
      ];

      setData(() => toSetUp);
    },
  });

  useEffect(() => {
    if (selectedFlter) {
      const dataToSetUp = selectedFlter?.value
        ? JSON.parse(selectedFlter.value.data)
        : [];

      dispatchFilters({
        type: 'init',
        data: dataToSetUp,
      });

      setSelectedData(() =>
        data.filter((ts) => Object.keys(dataToSetUp)?.includes(ts.label)),
      );

      const fileds = selectedFlter?.value
        ? JSON.parse(selectedFlter.value.fields)
        : TABLE_FILEDS_OPTIONS;

      setHeaders(fileds);

      const sharedFor = selectedFlter?.value?.shared_for;

      setSelectedShared(() =>
        admins.filter((admin) => sharedFor?.includes(admin?.value)),
      );
    }
  }, [selectedFlter]);

  useEffect(() => {
    getAllPosibleFiledsToFilterMutation.mutate();
  }, []);

  return (
    <>
      <Wrapper>
        <SelectInput
          showLabel={false}
          options={savedFilters}
          setSelected={setSelectedFilter}
          selected={selectedFlter}
        />
        <CustomEditButton text="Options" onClick={() => setOptions(true)} />
        <CustomDeleteButton
          text="Search"
          onClick={() => triggerHandleSearch()}
        />
      </Wrapper>
      {options && (
        <PopUp setClose={setOptions}>
          <OptionsWrapper>
            <Title>Display fileds</Title>
            <SelectInput
              selectWidth={900}
              showLabel={false}
              options={TABLE_FILEDS_OPTIONS}
              setSelected={setHeaders}
              selected={headers}
              multiple={true}
            />
            <SearchFilter
              filters={filters}
              dispatchFilters={dispatchFilters}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              nativeLanguages={nativeLanguages}
              hobbies={hobbies}
              speakers={speakers}
              chatConfigs={chatConfigs}
              countries={countries}
              data={data}
            />
            <Title>Saving</Title>
            <FormSaving onSubmit={triggerHandleSaveFilters}>
              <SelectInput
                selectWidth={380}
                options={admins}
                setSelected={setSelectedShared}
                selected={selectedShared}
                multiple={true}
                showLabel={false}
              />
              <Input
                width={140}
                inputRef={nameInputRef}
                required={true}
                requiredSign={true}
                placeholder="Type Name"
                value={selectedFlter?.value?.name}
                name="Filter Name"
              />
              <CustomSaveButton text="Create or Update" />
            </FormSaving>
          </OptionsWrapper>
        </PopUp>
      )}
    </>
  );
};

export default Search;
