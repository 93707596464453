import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { Color } from '@/common/colors/colors';
import moment from 'moment';
import Input from '@/common/components/custom/Input';
import SelectInput from '@/common/components/custom/SelectInput';
import ToggleSwitch from '@/common/components/ToggleSwitch';
import { useMutation } from 'react-query';
import { findNativeLanguages } from '@/api/query/nativeLanguages';
import Loading from '@/common/components/Loading';

const SelectBarContainer = styled.form`
  border-radius: 10px;
  background-color: ${Color.White};
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border: 1px dashed grey;
`;

const SearchButton = styled.button`
  padding: 10px 20px;
  background-color: ${Color.DarkBlue};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-left: 20px;

  &:hover {
    background-color: ${Color.Grey};
  }
`;

const SOURCES_OPTIONS = [
  {
    label: 'mobile',
    value: 'mobile',
  },
  {
    label: 'desktop',
    value: 'desktop',
  },
  {
    label: 'both',
    value: null,
  },
];

const SearchBar = ({ handleSearch, uniqueIp, uniqueUid, onInlucderChange }) => {
  const [selectedSource, setSelctedSource] = useState(SOURCES_OPTIONS[2]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [languages, setLanguages] = useState();

  const fromRef = useRef();
  const toRef = useRef();

  const handleSearchCheck = (e) => {
    e?.preventDefault();

    const payload = {};

    payload.from = moment(fromRef.current.value).startOf('day').toISOString();
    payload.to = moment(toRef.current.value).endOf('day').toISOString();
    payload.source = selectedSource.value;
    payload.country = selectedCountry.value;

    handleSearch(payload);
  };

  const handleLoadLanguages = useMutation({
    mutationFn: () => findNativeLanguages(),
    onSuccess: ({ data }) => {
      const l = data.map((d) => ({ label: d.name, value: d.short }));
      setLanguages(() => l);
    },
  });

  useEffect(() => {
    handleLoadLanguages.mutate();
  }, []);

  return (
    <>
      {handleLoadLanguages.isLoading && <Loading />}
      <SelectBarContainer onSubmit={handleSearchCheck}>
        <div>
          <Input
            type="date"
            inputRef={fromRef}
            name="From date"
            inputWidth={120}
            width={100}
            required={true}
          />
          <Input
            type="date"
            inputRef={toRef}
            name="To date"
            inputWidth={120}
            width={100}
            required={true}
          />
          <Input
            type="text"
            name="Includer"
            inputWidth={120}
            width={100}
            onChange={onInlucderChange}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <SelectInput
            name="Source"
            width={80}
            selectWidth={150}
            options={SOURCES_OPTIONS}
            selected={selectedSource}
            setSelected={setSelctedSource}
          />
          <SelectInput
            name="Language"
            width={80}
            selectWidth={150}
            options={languages}
            selected={selectedCountry}
            setSelected={setSelectedCountry}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <ToggleSwitch toggleRef={uniqueIp} width={120} text={'Unique IP:'} />
          <ToggleSwitch
            toggleRef={uniqueUid}
            width={120}
            text={'Unique UID:'}
          />
        </div>

        <SearchButton type="submit">Search</SearchButton>
      </SelectBarContainer>
    </>
  );
};

export default SearchBar;
