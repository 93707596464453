import { useLanguageData } from '@/common/hooks/useLanguageData';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;
`;

const Title = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
`;

const BoxWrapper = styled.div`
  display: flex;
  overflow: auto;
  scrollbar-color: transparent transparent;
  gap: 24px;
  margin-top: 8px;
`;

const Box = styled(Link)`
  border: 1px solid #c6bce7;
  position: relative;
  width: 150px;
  text-decoration: none;
  border-radius: 8px; /* Make it a circle with rounded corners */
  background-color: white !important;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 130px;

  overflow: hidden;

  img {
    border-radius: 0px 0px 8px 8px;
    width: 100%;
    height: 100%;
  }
`;

const TopTitle = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 0px 2px 0px;
  justify-content: center;
  align-items: center;
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
`;

const TopSubtitle = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  padding: 0px 0px 4px 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const WrapperElements = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 800px;
`;

const ForYouSectionDesktop = ({ data = {}, translation }) => {
  const common = useLanguageData('common');

  const randData = useMemo(() => {
    let result = [];

    if (data) {
      for (let i = 0; i < 4; i++) {
        if (data.lesson?.length > 0 && data.lesson[i]) {
          result.push({
            _id: data.lesson[i]._id,
            group: 'scenes',
            type: common['scenes'],
            name: data.lesson[i].name,
            file: data.lesson[i].file[0]?.base64,
          });
        }

        if (data.flashcard?.length > 0 && data.flashcard[i]) {
          result.push({
            _id: data.flashcard[i]._id,
            group: 'flashcards',
            type: common['flashcards'],
            name: data.flashcard[i].name,
            file: data.flashcard[i].file[0]?.base64,
          });
        }

        if (data.wordLessonType?.length > 0 && data.wordLessonType[i]) {
          result.push({
            _id: data.wordLessonType[i]._id,
            group: 'words',
            type: common['words'],
            name: data.wordLessonType[i].translation,
            file: data.wordLessonType[i].file[0]?.base64,
          });
        }
      }
    }

    return result;
  }, [data]);

  const getNavigationLink = (id, type) => {
    if (type === 'scenes') {
      return `/learn/scenes/${id}`;
    }

    if (type === 'flashcards') {
      return `/learn/flashcards/flashcard/${id}`;
    }

    if (type === 'words') {
      return `/learn/words/${id}`;
    }
  };

  return (
    <Wrapper>
      <WrapperElements>
        <Title>{translation.forYou}</Title>
        <BoxWrapper>
          {randData.slice(0, 5).map((d) => (
            <Box to={getNavigationLink(d._id, d.group)} key={d._id}>
              <TopTitle>{d?.type}</TopTitle>
              <TopSubtitle>{d?.name}</TopSubtitle>
              <ImageWrapper>
                <img src={d?.file} />
              </ImageWrapper>
            </Box>
          ))}
        </BoxWrapper>
      </WrapperElements>
    </Wrapper>
  );
};

export default ForYouSectionDesktop;
