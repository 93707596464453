import CustomDeleteButton from '@/common/components/custom/buttons/CustomDeleteButton';
import Input from '@/common/components/custom/Input';
import SelectInput from '@/common/components/custom/SelectInput';
import PopUp from '@/common/components/PopUp';
import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import Papa from 'papaparse';
import { useMutation } from 'react-query';
import { useMessageQueue } from '@/common/message';
import { MessageContext } from '@/common/contexts/MessageContext';
import { createAdsDataFromCSV } from '@/api/query/advert';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
`;

const IMPORT_TYPES = [
  {
    label: 'MS',
    value: 'microsoft',
    required_fields: ['day', 'ads', 'cost'],
  },
  {
    label: 'Google',
    value: 'google',
    required_fields: ['day', 'ads', 'cost', 'ads_aditional_name'],
  },
];

const ImportData = ({ setClose }) => {
  const fileRef = useRef();

  const [selectedType, setSelectedType] = useState();

  const { addMessage } = useContext(MessageContext);

  const handleAddAdvertFromCSV = useMutation({
    mutationFn: (payload) => createAdsDataFromCSV(payload),
    onSuccess: () => {
      addMessage('Added', 'success');
      setClose(() => false);
    },
    onError: () => {
      addMessage('Error', 'error');
    },
  });

  const handleFileUpload = () => {
    const file = fileRef.current.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const csvData = event.target.result;

        Papa.parse(csvData, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            let isValid = true;

            selectedType.required_fields.forEach((f) => {
              if (!Object.keys(results.data[0]).includes(f)) {
                isValid = false;
              }
            });

            if (!isValid) {
              addMessage('CSV File doesnt have required fields', 'error');
              return;
            }

            const payload = {
              type: selectedType.value,
              data: results.data,
            };

            handleAddAdvertFromCSV.mutate(payload);
          },
          error: function (err) {
            addMessage(err, 'error');
          },
        });
      };

      reader.onerror = () => {
        console.error('File could not be read.');
      };

      reader.readAsText(file);
    } else {
      alert('Please select a file.');
    }
  };

  return (
    <PopUp setClose={setClose}>
      <Wrapper>
        <h3>Data Import</h3>
        <div>
          <Input
            type="file"
            width={100}
            name="File"
            inputWidth={200}
            inputRef={fileRef}
          />
        </div>
        <SelectInput
          name={'Source Type'}
          selected={selectedType}
          setSelected={setSelectedType}
          options={IMPORT_TYPES}
          width={100}
          selectWidth={225}
        />
        <div style={{ flex: 1 }} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <CustomDeleteButton
            text={'Proccess data'}
            onClick={handleFileUpload}
          />
        </div>
      </Wrapper>
    </PopUp>
  );
};

export default ImportData;
