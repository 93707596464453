import React, { useRef } from 'react';
import { styled } from 'styled-components';
import { Color } from '@/common/colors/colors';
import moment from 'moment';
import Input from '@/common/components/custom/Input';
import ToggleSwitch from '@/common/components/ToggleSwitch';

const SelectBarContainer = styled.form`
  border-radius: 10px;
  background-color: ${Color.White};
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border: 1px dashed grey;
`;

const SearchButton = styled.button`
  padding: 8px 16px;
  background-color: ${Color.DarkBlue};
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-left: 20px;

  &:hover {
    background-color: ${Color.Grey};
  }
`;

const SearchBar = ({ handleSearch, toogleRef }) => {
  const fromRef = useRef();
  const toRef = useRef();
  const currentDate = moment().format('YYYY-MM-DD');

  const handleSearchCheck = (e) => {
    e?.preventDefault();

    const payload = {};

    payload.ignoreUtms = toogleRef.current.checked || false;
    payload.from = moment(fromRef.current.value).format('YYYY-MM-DD');
    payload.to = moment(toRef.current.value).format('YYYY-MM-DD');
    handleSearch(payload);
  };

  return (
    <>
      <SelectBarContainer onSubmit={handleSearchCheck}>
        <Input
          type="date"
          inputRef={fromRef}
          name="From date"
          inputWidth={120}
          width={100}
          required={true}
          value={currentDate}
        />
        <Input
          type="date"
          inputRef={toRef}
          name="To date"
          inputWidth={120}
          width={100}
          required={true}
          value={currentDate}
        />
        <ToggleSwitch
          toggleRef={toogleRef}
          width={140}
          text={'Ignore UTMS (unique ip)'}
        />
        <SearchButton type="submit">Search </SearchButton>
      </SelectBarContainer>
    </>
  );
};

export default SearchBar;
