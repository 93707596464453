import { useMutation } from 'react-query';
import { handleRefund } from '@/api/query/order';

export const useRefund = (
  id,
  refetchCustomer,
  addMessage,
  setShowDecisionConfirmation,
) => {
  const mutation = useMutation(handleRefund, {
    onSuccess: () => {
      refetchCustomer();
      addMessage('Refunded', 'success');
      setShowDecisionConfirmation(() => false);
    },
    onError: () => {
      addMessage('Something went wrong with the refund', 'error');
    },
  });

  return mutation;
};
