import { updateCustomerOrder } from '@/api/query/order';
import PopUp from '@/common/components/PopUp';
import Input from '@/common/components/custom/Input';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import { MessageContext } from '@/common/contexts/MessageContext';
import moment from 'moment';
import React, { useContext, useRef } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

const Wrapper = styled.form`
  margin: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const AdminEditOrder = ({ edit, setEdit }) => {
  const priceRef = useRef();
  const expiredDateRef = useRef();

  const { addMessage } = useContext(MessageContext);

  const handleUpdateMutation = useMutation({
    mutationFn: (payload) => updateCustomerOrder(payload),
    onSuccess: () => {
      setEdit(() => null);
      addMessage('Updated', 'success');
    },
    onError: () => addMessage('Sth went wrong', 'error'),
  });

  const handleUpdate = (e) => {
    e?.preventDefault();

    const payload = {
      _id: edit._id,
      expired_date: moment(expiredDateRef.current.value).toISOString(),
      price: priceRef.current.value,
    };

    handleUpdateMutation.mutate(payload);
  };

  return (
    <PopUp setClose={setEdit}>
      <h2>Edit Order</h2>
      <Wrapper onSubmit={handleUpdate}>
        <Input
          inputRef={expiredDateRef}
          name="Expired Date"
          width={150}
          type="datetime-local"
          value={moment(edit.expired_date).format('YYYY-MM-DDTHH:mm')}
          required={true}
          requiredSign
        />
        <Input
          inputRef={priceRef}
          name="Price"
          width={150}
          value={edit.price}
          required={true}
          requiredSign
        />
        <CustomSaveButton />
      </Wrapper>
    </PopUp>
  );
};

export default AdminEditOrder;
