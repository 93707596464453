import Android1 from './images/android_1.png';
import Android2 from './images/android_2.png';
import Ios1 from './images/ios_1.png';
import Ios2 from './images/ios_2.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

export default {
  passwordRestart: {
    title: 'Reset Password',
    password: 'Password',
    repeatPassword: 'Repeat Password',
    restartPawssord: 'Reset Password',
  },

  paywall: {
    title: 'Wybierz plan, aby kontynuować naukę',
    buy: 'Kup',
    savePayment: 'Bezpieczne płatności obsługuje',
    skip: 'Pomiń',
    pickPlan: 'Wybierz plan',
    endPlanFirstPart: 'Twój dostęp zakończy się za',
    endPlanSecondPart: 'dni.',
    endPlanAditionalPart: 'dzień.',
  },
  register: {
    steps: {
      register: 'Registration',
      package: 'Package',
      summarize: 'Summary',
      step: 'Step ',
    },
    stepOne: {
      password: 'Password',
      errorGoogle: 'Log in using email',
      title: 'Registration',
      nameAndSurname: 'Name and Surname',
      placeholderNameAndSurname: 'e.g. Adam Smith',
      email: 'Email',
      placeholderEmail: 'e.g. example@synthiaiapp.com',
      password: 'Password',
      start: 'CREATE ACCOUNT',
      subtitle:
        'Password must contain at least 8 characters, including lowercase and uppercase letters, and a number.',
    },
    stepTwo: {
      title: 'Choose a plan for yourself',
      month: 'month',
      months: 'months',
      months2: 'months',
      value: 'zł/month',
      pick: 'choose',
      nowPay: 'Now you will pay',
      saveOne: 'You save',
      buy: 'Buy',
      savePayment: 'Secure payments provided by',
    },
    stepThree: {
      title: 'Summary',
      product: 'Product:',
      plan: 'Synthi Ai Package',
      forLong: 'Access for',
      data: 'data:',
      nameAndSurname: 'Name and Surname:',
      paymentForm: 'Payment method:',
      toPay: 'To pay today:',
      accept: 'I accept',
      rules: 'terms and conditions',
      payingAccept: 'By paying, you accept the',
      paymentRules: '"PayU Payment Rules"',
      buyAndPay: 'BUY AND PAY',
    },
  },
  succesPayment: {
    title: 'Payment successful!',
    subtitle: 'You will be redirected to the login page shortly.',
  },
  login: {
    title: 'Login',
    email: 'Email',
    password: 'Password',
    textSub: 'Forgot your password? Reset',
    restart: 'here',
    restartPasswordText:
      'Enter your email to reset your password. An email with the password will be sent to your mailbox.',
    placeholder: 'enter your email...',
    resetPassword: 'reset password',
    buttonText: 'Log in',
    errorLogin: 'Check if your password or email is correct',
    passwordResterted: 'Your password has been reset, check your email.',
    passwordError: "Unfortunately, we couldn't find your email.",
  },
  navBar: {
    home: 'Start',
    learn: 'Learn',
    profile: 'Profile',
  },
  home: {
    hello: 'Hello',
    question: 'What will we talk about today?',
    answer: 'Start a conversation',
    forYou: 'Recommended for You',
    popularNow: 'Popular now',
    doYouKnow: 'Did you know that...?',
    funFact: 'Fun fact of the day',
    new: 'New',
    newTitle: 'Scenes',
    newSubtitle:
      'Step into a role and play out a scene with an AI tutor on your chosen topic.',
    yourFavorite: 'Your favorites',
  },
  learn: {
    chat: {
      title: 'Chat',
      subtitle: 'Chat about any topic.',
    },
    fotoMode: {
      title: 'Photo puzzle',
      subtitle: 'Describe the picture as accurately as possible.',
    },
    scene: {
      title: 'Scenes',
      subtitle: 'Play a role with an AI tutor.',
      new: 'New',
    },
    word: {
      title: 'Words',
      subtitle: 'Expand your vocabulary.',
    },
    flashcard: {
      title: 'Flashcards',
      subtitle: 'Review and memorize words.',
    },
    seeMore: 'See all',
  },
  common: {
    description:
      'SynthiAi - Your Intelligent Assistant for Learning Italian. Learn to speak Italian fluently with the help of artificial intelligence. Discover a new, effective approach to language learning. Start today!',
    deletedFromFavorite: 'Removed from favorites',
    addedToFavorite: 'Added to favorites',
    flashcards: 'Flashcards',
    scenes: 'Scenes',
    words: 'Words',
    or: 'or',
    continueWithFB: 'Continue with Facebook',
    privacyLink: '/Privacy_EN.pdf',
    termsLink: '/Terms_Conditions_EN.pdf',
  },

  profile: {
    days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    top: {
      profile: 'Profile',
      accessTo: 'Access to:',
      profileImage: 'Profile Picture',
      nameAndSurname: 'Name and Surname',
      password: 'Password',
      change: 'Change',
      changeProfileImage: 'Change profile picture',
      changeNameAndSurname: 'Change your name and surname',
      changeNameAndSurnamePlaceHolder: 'Enter name and surname',
      save: 'Save',
      changePassword: 'Change your password',
      passwordInfo:
        'Password must contain at least 8 characters, including lowercase and uppercase letters, and a number',
      repeatPassword: 'Repeat password',
      passwordChanged: 'Password changed',
      error: 'Oops something went wrong',
      nameChanged: 'Name changed',
      profileChanged: 'Profile Changed',
    },
    plan: {
      yourPlan: 'Twój plan',
      extend: 'Przedłuż swój plan',
      short_extend: 'Przedłuż',
      currentPlan: 'Obecny plan:',
      activeToPlan: 'Aktywny do:',
      currentPlanLong: 'Twoj obecny plan',
    },
    middle: {
      yourMedals: 'Your achievements',
      oneDay: 'day',
      twoDays: 'days',
      words: 'Words',
      learningTime: 'Learning time',
      streak: 'Streak',
      streakRecord: 'Streak record',
      yourRecord: 'Your record:',
      currentRecord: 'Current result:',
      words: 'Words',
      wordsInWeek: 'Number of words this week:',
    },
    bottom: {
      yourTutor: 'Your AI tutor',
      change: 'Change',
      logout: 'Log out',
      FAQ: 'FAQ',
      shareOpinion: 'Share your opinion',
      yourPlan: 'Your plan',
      hobby: 'Hobbies',
      learningSetting: 'Learning settings',
      back: 'Back',
      dayTarget: 'Daily target',
      languageLevel: 'Language level',
      changeYourLangLevel: 'Change your language level.',
      pickNewTutor: 'Pick a new tutor',
      yourDecision:
        'Your decision will affect the style and atmosphere of the lessons.',
      tutroChanged: 'Tutor changed',
      error: 'Oops something went wrong',
      englishLevelChanged: 'Your level has been changed',
      levelsOptions: [
        {
          name: 'Beginner A1',
          description:
            "You're starting your adventure with the English language",
          value: 'basic',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Elementary A2',
          description:
            'You can understand basic phrases in simple everyday dialogues.',
          borderRadius: '0',
          value: 'beginner',
        },
        {
          name: 'Pre-Intermediate B1',
          description:
            'You can hold simple but understandable conversations on familiar topics.',
          borderRadius: '0',
          value: 'intermediate',
        },
        {
          name: 'Intermediate B2',
          description: 'You can communicate fluently in most situations.',
          borderRadius: '0',
          value: 'advanced',
        },
        {
          name: 'Upper-Intermediate C1+',
          description:
            'You can use the language effectively in complex contexts',
          value: 'expert',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      changeDailyTarget: 'Change daily learning target.',
      deletedFromFavorite: 'Removed from interests',
      addedToFavorite: 'Added to interests',
      lessonTime: [
        {
          name: 'Quick lesson ',
          dsecription: 'Perfect option for express learning',
          time: '5 min',
          value: 'five_minutes',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Standard session',
          dsecription: 'Great choice for balanced learning',
          time: '10 min',
          value: 'ten_minutes',
          borderRadius: '0',
        },
        {
          name: 'Intensive practice',
          dsecription: 'Best option for enthusiasts',
          time: '15 min',
          value: 'fifteen_minutes',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      dailyTargetChanged: 'Daily target changed!',
      pickIntresets: 'Choose at least 3 interests.',
      activeTo: 'Plan active until:',
      daysLeft: 'Days left until the end of the plan:',
      opinionSubtitle:
        'We are constantly improving our application. Share your opinion about what we can change in the application or what you like the most.',
      startTyping: 'Start typing',
      faq: 'FAQ',
      send: 'Send',
      withProblems: 'If you have any questions/problems, please contact us at:',
      contactMail: 'support@SynthiAiapp.com',
      faqConfig: [
        {
          id: 1,
          question: 'Sound is not working for me.',
          answer: `If you're having trouble with sound, consider the following tips:

              Check sound permissions in your browser.

              Make sure your device's volume is appropriate.

              Check the correct connection of speakers/headphones.

              Review system sound settings to ensure apps are not muted.

              Try using a different device or contact technical support.
          `,
        },
        {
          id: 2,
          question: "I can't record my voice.",
          answer: `If you're having trouble recording sound, consider the following tips:

              Check microphone permissions in your browser.

              Make sure the mobile browser has access to the microphone.

              Check the correct connection of the microphone.

              Consider using a different device or contact technical support.
          `,
        },
        {
          id: 3,
          question: 'Can I use the app on more than one device?',
          answer: `Of course, simply log in on any compatible device to enjoy a seamless learning experience.`,
        },
        {
          id: 4,
          question: 'Can I use Synthi Ai offline?',
          answer: `No, Synthi Ai requires an internet browser with internet access.`,
        },
        {
          id: 5,
          question: 'How can I change the AI tutor?',
          answer: `Go to the "Tutor" tab in Synthi Ai settings. Explore and select your preferred tutor.`,
        },
        {
          id: 6,
          question: 'How do I extend access to the app after it expires?',
          answer: `You can renew your plan by logging in after it expires.`,
        },
        {
          id: 7,
          question: 'Can I receive an invoice?',
          answer: `Contact support@SynthiAiapp.com with your VAT number and company details.`,
        },
        {
          id: 8,
          question: 'How can I contact technical support?',
          answer: `Send an email to support@SynthiAiapp.com with details of the problem, device information, and browser details.`,
        },
      ],
    },
    rules: 'terms and conditions',
    privacy: 'Privacy Policy',
    span: ' and ',
  },
  welcome: {
    languagePicker: {
      title: 'Choose the language you want to learn',
      next: 'Next',
      langToLearn: 'Language to Learn',
      successTitle: 'Language to Learn has been changed',
      config: [
        {
          label: 'English',
          label2: 'English',
          labelInside: 'English',
          value: 'en',
        },
        {
          label: 'German',
          label2: 'German',
          labelInside: 'German',
          value: 'de',
        },
        {
          label: 'Spanish',
          label2: 'Spanish',
          labelInside: 'Spanish',
          value: 'es',
        },
        {
          label: 'French',
          label2: 'French',
          labelInside: 'French',
          value: 'fr',
        },
        {
          label: 'Italian',
          label2: 'Italian',
          labelInside: 'Italian',
          value: 'it',
        },
      ],
    },
    zeroScreen: {
      welcomeInApp: 'Welcome to the Synthi AI app!',
      start: "LET'S GET STARTED",
      welcomeMobile: 'Welcome',
      config: {
        chrome: {
          title: 'Install the app on your Android device.',
          text1: 'In the Chrome browser, click',
          text1bold: ' Settings',
          image1: Android1,
          text2: 'Then click',
          text2bold: ' Add to Home Screen',
          image2: Android2,
          text3: 'Done! You will now find Synthi Ai on the home screen.',
          image3: Last,
        },
        safari: {
          title: 'Install the app on your iOS device.',
          text1: 'In the Safari browser, click',
          text1bold: ' Share',
          image1: Ios1,
          text2: 'Then click',
          text2bold: ' Add to Home Screen',
          image2: Ios2,
          text3: 'Done! You will now find Synthi Ai on the home screen.',
          image3: Last,
        },
      },
    },
    firstScreen: {
      title: 'Choose your tutor!',
      subtitle:
        'Your decision will affect the style and atmosphere of the lessons.',
      next: 'NEXT',
    },
    secondScreen: {
      title: 'What is your current {language} level?',
      config: [
        {
          name: 'Beginner A1',
          description:
            "You're starting your adventure with the {language} language.",
          value: 'basic',
        },
        {
          name: 'Elementary A2',
          description:
            'You can understand basic phrases in simple, everyday dialogues.',
          value: 'beginner',
        },
        {
          name: 'Pre-Intermediate B1',
          description:
            'You can hold simple but understandable conversations on familiar topics.',
          value: 'intermediate',
        },
        {
          name: 'Intermediate B2',
          description: 'You can communicate fluently in most situations.',
          value: 'advanced',
        },
        {
          name: 'Upper-Intermediate C1+',
          description:
            'You can use the language effectively in complex contexts.',
          value: 'expert',
        },
      ],
      next: 'NEXT',
    },
    thirdScreen: {
      title: 'What are your interests?',
      subtitle: 'Choose at least 3 interests.',
      next: 'NEXT',
    },
    fourthScreen: {
      title: 'How many minutes do you want to learn per day?',
      subtitle: 'Choose your daily goal.',
      next: "LET'S START!",
      config: [
        {
          name: 'Quick lesson',
          dsecription: 'Perfect option for express learning',
          time: '5 min',
          value: 'five_minutes',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Standard session',
          dsecription: 'Great choice for balanced learning',
          time: '10 min',
          value: 'ten_minutes',
          borderRadius: '0',
        },
        {
          name: 'Intensive practice',
          dsecription: 'Best option for enthusiasts',
          time: '15 min',
          value: 'fifteen_minutes',
          borderRadius: '0 0 8px 8px ',
        },
      ],
    },
  },
  features: {
    topbar: {
      back: 'Back',
    },
    fotoMode: {
      welcome: {
        title: 'Photo Puzzle',
        subtitle:
          'Describe what you see in the picture in as much detail as possible on the selected topic.',
        start: 'Start!',
      },
      info: {
        startTyping: 'Start typing...',
        newTask: 'New task',
        send: 'Send',
      },
    },
    flashcards: {
      title: 'Flashcards',
      subtitleTop:
        'Learn and memorize new words through interactive flashcard learning.',
      addCategory: 'Add your category',
      subtitle: 'Enter the name of your category',
      type: 'type category...',
      add: 'Add',
      previous: 'Previous flashcard',
      task: 'Task',
      changeLevel: 'Change level',
      dontKnow: "Don't know",
      know: 'Know',
      pickPairs: 'Match pairs',
      goodJob: 'Good job!',
      titleEnd: 'Your engagement is impressive',
      subtitleEnd: 'Are you ready for more challenges?',
      repeat: 'Repeat category',
      other: 'Choose a new category',
      level: {
        title: 'Change flashcard level',
        beginner: ' A1-A2 beginner',
        intermediate: 'B1-B2 intermediate',
        advance: 'C1-C2 advance',
        change: 'Change',
      },
    },
    scene: {
      title: 'Scenes',
      subtitle:
        'Take on a role and play out a scene with an AI tutor on the chosen topic.',
      suggest: 'Suggest a new scene',
      talk: 'Talk',
      deletedFromFavorite: 'Removed from favorites',
      addedToFavorite: 'Added to favorites',
    },
    word: {
      title: 'Words',
      subtitle:
        'Expand your vocabulary in key conversation areas with an AI tutor.',
      suggest: 'Suggest a new word topic',
      deletedFromFavorite: 'Removed from favorites',
      addedToFavorite: 'Added to favorites',
    },
  },
  cookie: {
    title: 'Cookie Information',
    subtitle:
      'This website uses cookies to ensure you get the best experience and personalized content. If you want to learn more, please read our ',
    link: 'privacy policy',
    accept: 'ACCEPT',
    denied: 'DECLINE',
  },
  talkingBar: {
    allowMicrofon: 'Please allow access to the microphone',
    typeMessage: 'Type a message...',
    record: 'Record',
    recording: 'Recording',
  },
  hands: {
    giveUsInfo: 'Give us your feedback',
    thanksForGrading: 'Thank you for rating!',
    subtitle: 'Your voice matters to us!',
    subtitle2: 'Tell us what you think about this lesson:',
    skip: 'SKIP',
    send: 'SEND',
  },
  suggestConfig: {
    profile: {
      title: 'Share your feedback!',
      subtitle:
        'We are constantly improving our application. Share your opinion about what we can change in the application or what you like the most.',
    },
    word: {
      title: 'Suggest a new word topic',
      subtitle: '',
    },
    scene: {
      title: 'Suggest a new scene',
      subtitle: '',
    },
    startTyping: 'Start typing',
    send: 'SEND',
    thx: 'Feedback added! Thank you!',
    error: 'Oops, something went wrong',
  },
  checkout: {
    leftTitle: 'Choose a plan for you',
    rightTitle: 'Complete the details',
    savePayments: 'Secure payments are handled by',
    subscribe: 'Subscribe',
  },
  dailyV2: {
    hint: {
      title: 'Hint',
      pick: 'Pick',
    },
    feedback: {
      yourMessage: 'Your message',
      goodMessage: 'Correct message',
      explanation: 'Explanation',
    },
    tutorial: {
      title: 'How to use the chat?',
      skip: 'Skip',
      next: 'NEXT',
      start: 'Start',
      screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
      config: [
        [
          { title: 'Click to mute Tutor sound', image: VolumeUp },
          { title: 'Click to give feedback', image: HandsUp },
          { title: 'Click to open the instructions', image: Tutorial },
        ],
        [
          { title: 'Click to replay', image: VolumeUpBlank },
          {
            title: 'Click to see the translation',
            image: TranslationBlank,
          },
          { title: 'Click to see the hint', image: HintBlank },
          { title: 'Click to report a problem', image: InfoBlank },
        ],
        [
          { title: 'Your answer is correct', image: GreenCircle },
          {
            title: 'Click to see the corrected answer and explanation',
            image: YellowCircle,
          },
        ],
        [
          { title: 'Click the text field to write a message' },
          { title: 'Click to record a message', image: Microphone },
        ],
      ],
    },
  },
  problem: {
    title: 'Report a problem',
    subtitle: 'Describe the issue regarding the AI message',
    send: 'Send',
    messageSend: 'Message sent',
    error: 'Something went wrong, write to us :)',
  },
};
